import React, { useCallback } from 'react';
import { Box, Typography, Grid, Container, Button } from '@mui/material';
import { styled } from '@mui/system';
import cause1 from './images/about/lifeschool.jpeg';
import cause2 from './images/about/stich.jpeg';
import { Link } from 'react-router-dom';
import cause6 from './images/about/goat.jpeg';
import project1 from './images/about/life2.jpeg';
import project2 from './images/about/gudalur.png';
import project4 from './images/about/goat2.jpeg';

import heroImage from './images/background/detailabout.png';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

function Projectpage() {
  const HeroSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '45vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    textAlign: 'center',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  }));

  const HeroContent = styled(Box)(({ theme }) => ({
    zIndex: 1,
    maxWidth: '800px',
    padding: theme.spacing(4),
  }));

  const SectionTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    marginBottom: theme.spacing(4),
    position: 'relative',
    display: 'inline-block',
    color: '#302c51',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '-10px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '60px',
      height: '3px',
      backgroundColor: '#d40032',
    },
  }));

  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#d40032',
    color: 'white',
    borderRadius: '25px',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: '#c0002f',
    },
  }));

  const SectionDescription = styled(Typography)(({ theme }) => ({
    maxWidth: '800px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
    textAlign: 'center',
    color: '#555',
  }));

  const navigate = useNavigate();
  
  const scrollToDonate = useCallback(() => {
    navigate('/donateUs');
    setTimeout(() => {
      const donateSection = document.getElementById('donate-us-section');
      if (donateSection) {
        donateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  }, [navigate]);

  const completedprojects = [
    {
      title: "Life School Programme",
      image: project1,
      route: "/lifeschool/1",
    },
    {
      title: "From Floor to Bench:Gudalur",
      image: project2,
      route: "/gudalur/1",
    },
    {
      title: "Goat Rearing for Women",
      image: project4,
      route: "/goat/1",
    },
  ];

  const latestprojects = [
    {
      id: 'lifeschool',
      image: cause1,
      title: 'Unique Life School Programme',
      description: 'A nurturing space for children to grow and develop leadership skills for a brighter tomorrow.',
      raised: '25,000',
      goal: '50,000',
      progress: 50,
    },
    {
      id: 'tailoring',
      image: cause2,
      title: 'Tailoring Center: Stitching Future',
      description: 'Equipping women with tailoring skills to create sustainable livelihoods and become successful entrepreneurs.',
      raised: '40,000',
      goal: '80,000',
      progress: 50,
    },
    {
      id: 'goat',
      image: cause6,
      title: 'Goat Rearing: Empowering Women',
      description: 'Providing women with the tools and knowledge to succeed in goat farming, boosting independence.',
      raised: '80,000',
      goal: '40,000',
      progress: 50,
    },
  ];

  return (
    <>
      <div>
        <HeroSection>
          <HeroContent>
            <Typography variant="h1" sx={{ fontWeight: 600, fontSize: { xs: '2rem', md: '2.5rem' }, marginBottom: 2 }}>
              Explore Our Projects
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 300, marginBottom: 4 }}>
              Engage in Meaningful Projects that Inspire Hope.
            </Typography>
          </HeroContent>
        </HeroSection>

        <section className="causes-section-four">
          <div className="auto-container">
          <div className="sec-title text-center">
              <h1 style={{ textAlign: 'left',marginTop: '-10px' }}>Our Latest Projects</h1>
            </div>
            <div className="cause-wrapper">
              <Grid container spacing={3}>
                {latestprojects.map((project, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <div className="cause-block-four">
                      <div className="inner-box">
                        <div className="image">
                          <img src={project.image} alt={project.title} />
                        </div>
                        <div className="lower-content">
                          <div className="wrapper-box">
                            <h4>
                              <StyledLink
                                to={`/${project.id}/${project.id}`}
                              >
                                {project.title}
                              </StyledLink>


                              
                            </h4>
                            <div className="text">{project.description}</div>
                            <div className="info-box">
                              <div className="raised-donation">
                                <span className="raise-donation">Raised:</span> {project.raised}
                              </div>
                              <div className="count-box">
                                <span className="count-text" data-speed="3000" data-stop={project.progress}>
                                  {project.progress}
                                </span>
                                <span className="affix">%</span>
                              </div>
                              <div className="goaled-donation">
                                <span className="goal-donation">Goal:</span> {project.goal}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </section>

        {/* Completed Missions Section */}
        <section className="projects-sectionprojects">
          <div className="auto-container">
            <div className="sec-title text-center">
              <h1 style={{ textAlign: 'left',marginTop: '-10px' }}>Our Completed Projects</h1>
            </div>
            <div className="row">
              {completedprojects.map((project, index) => (
                <ProjectCard key={index} project={project} />
              ))}
            </div>
          </div>
        </section>

        <Box sx={{ py: 6, backgroundColor: '#f9f9f9', textAlign: 'center' }}>
          <Container>
            <SectionTitle variant="h4">Be Part of the Impact</SectionTitle>
            <SectionDescription variant="body1">
              Get involved in our initiatives to uplift communities. Your support, whether through volunteering or donations, helps provide essential resources and create lasting change. Join us in making a difference in the lives of individuals and families.
            </SectionDescription>
            <StyledButton variant="contained" size="large" onClick={scrollToDonate}>
              Support for a Cause
            </StyledButton>
          </Container>
        </Box>

        {/* Footer Section */}
        <Footer />
      </div>
    </>
  );
}

const StyledLink = styled(Link)(({ theme }) => ({
  color: '#302c51', // Default color for the title
  textDecoration: 'none',
  '&:hover': {
    color: '#d40032', // Change color on hover to red
  },
}));

const ProjectCard = ({ project }) => {
  return (
    <div className="project-block-one">
      <div className="inner-box">
        <div className="image">
          <img src={project.image} alt={project.title} />
          <div className="gradient-overlay"></div>
        </div>
        <h3>
          <StyledLink to={project.route}>
            {project.title}
          </StyledLink>
        </h3>
      </div>
    </div>
  );
};

export default Projectpage;
