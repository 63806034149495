import React, { useEffect,useCallback } from 'react';
import { Box, Container, Typography, Grid, IconButton, Button, useTheme, useMediaQuery } from '@mui/material';
import { ArrowBack, VisibilityOutlined, ChatBubbleOutline } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom'; 
import heroImage from './images/background/detailabout.png'; 
import blogImage from './images/about/life3.jpeg';
import Footer from './Footer';
import gud1 from './images/about/gud1.jpeg';
import gud2 from './images/about/gud2.jpeg';
import gud3 from './images/about/gud3.jpeg';
import gud4 from './images/about/gud4.jpeg';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));


const SidebarSection = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  boxShadow: '0 6px 24px rgba(0,0,0,0.1)',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)( {
  fontSize: '1.7rem',
  fontWeight: 600,
  marginBottom: '1rem',
});
const DonateButton = styled(Button)( {
  backgroundColor: ' #d40032',
  color: '#fff',
  fontWeight: 'bold',
  borderRadius: '24px',
  padding: '10px 20px',
  marginTop: '16px',
  '&:hover': {
    backgroundColor: '#b30029',
  },
});
const SectionContent = styled(Typography)( {
  fontSize: '1rem',
  color: '#555',
  marginBottom: '1rem',
});
const Lifeschooldetail = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const scrollToDonate = useCallback(() => {
    navigate('/donateUs');
    setTimeout(() => {
        const donateSection = document.getElementById('donate-us-section');
        if (donateSection) {
            donateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } 
    }, 100);
}, [navigate]);
  
  const blog = {
    title: 'Transforming classrooms and futures—Ray of Love Development Foundation provides desks, dignity, and dreams for a brighter tomorrow.',

    date: 'May 4, 2024',
    author: 'Shajan John Edakkad',
    views: 123,
    comments: 45,
    content: `

         <p style="text-align: justify; line-height: 1.5;"><b>"The distance from the floor to the bench is 13 years."</b></p>
        <p style="text-align: justify; line-height: 1.5;">And today, that distance has finally been bridged. For over a decade and a half, the children of Ambilimala School in Ovali Panchayat have endured long hours of study sitting on hard, cement floors. Now, thanks to the dedicated efforts of Christ AG Church, this long-awaited dream of seating and comfort has become a reality.
The children of Ambilimala School will no longer have to experience the discomfort of the floor. From now on, they will have desks—a small but profound change that holds the promise of a brighter future. This vision of progress was first imagined by the leadership of Christ AG Church in New York, who saw in Ambilimala a place with potential, a village that deserved access to resources fostering education and growth. Today, their vision has been realized.

</p>
      <img src="${gud1}" alt="Blog Detail" style="width: 100%; margin: 20px 0;" />


   
  <p style="text-align: justify; line-height: 1.5;">  In their search for communities in need, SeaForth village within Ovali Panchayat was selected as the center of this mission. Known for its dedication to self-sufficiency, the village embodies resilience and unity. Ambilimala School, serving children in grades 1 to 5 since its establishment in 2010, had always struggled without basic classroom furniture. Local families have spent over 13 years advocating for desks and benches, but with no support from the government, the situation seemed unchanging—until now.
When the leaders at Christ AG Church heard about this long-standing challenge, they knew immediate action was essential. Under the guidance of Pastor George P., Chacko, and Director of Social Development George Abraham Vazhayil, the church collaborated with the Ray of Love Development Foundation to make this vision a reality. Together, they gathered resources and brought the necessary support to transform this learning environment.

</p>
 <div class="detailimage-container">
    <img src="${gud2}" alt="Blog Detail" class="life-image" />
    <img src="${gud3}" alt="Blog Detail" class="life-image" />
</div>
 

  <p style="text-align: justify; line-height: 1.5;">A ceremony was held at the school to commemorate this impactful change. Presiding over the event was Oveli Municipality Panchayat Vice Chairman Sahadevan, while Director James Chacko of the Ray of Love Development Foundation formally inaugurated the project. PTA President Ashraf, Panchayat Councilor Subramanian, and Headmistress Sasikala offered their heartfelt thanks, and Project Coordinator Saji Mathai Kathet shared the initiative's story, led by local coordinator Sheeja B. Gudallur.
This project represents more than just furniture; it symbolizes hope, progress, and the commitment to nurturing the potential of Ambilimala’s children. With these benches, students now have a place to sit and learn in comfort, an environment where their growth is fostered, and dreams are encouraged. Through the combined efforts of the church, the foundation, and the community, a dream has come true, one that will resonate in the lives of these children and their village for generations.

</p>
<img src="${gud4}" alt="Blog Detail" style="width: 100%; margin: 20px 0;" />

 
    `,
  };

  return (
    <Box sx={{ backgroundColor: '#f8f8f8' }}>
      {/* Hero Section */}
      <HeroSection>
        <HeroContent>
          <Typography variant="h5" sx={{ fontWeight: 600, fontSize: { xs: '1.5rem', md: '2.5rem' }, marginBottom: 2 }}>
          From Floor to Bench: Bringing Comfort and Hope to Ambilimala School
          </Typography>
          {/* <Typography variant="h7" sx={{ fontWeight: 400, fontSize: { xs: '1rem', md: '1.3rem' }, marginBottom: 4 }}>
          Let’s rock and sing, let’s grow together!
          </Typography> */}
        </HeroContent>
      </HeroSection>
      



        {/* Blog Content Section */}
        <Container>
        <Grid container spacing={3} sx={{ my: 4 }}>
          <Grid item xs={12} md={12} sx={{ mb: 4 }}  >
            <Box sx={{ backgroundColor: 'white', borderRadius: 2, boxShadow: '0 4px 20px rgba(0,0,0,0.1)', p: 4 }}>
              <Typography variant="caption" sx={{ color: '#888', mb: 1 }}>
                {blog.date}
              </Typography>
              <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 , fontSize: { xs: '1.5rem', md: '2rem' },}}>
                {blog.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#555', mb: 2 }}>
                by {blog.author}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                  <VisibilityOutlined sx={{ fontSize: 20, color: '#d40032 ' }} />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>{blog.views} views</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ChatBubbleOutline sx={{ fontSize: 20, color: '#d40032 ' }} />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>{blog.comments} comments</Typography>
                </Box>
              </Box>
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            </Box>
          </Grid>

          {/* Sidebar Section */}
          <Grid container spacing={2} style={{ marginLeft: 4 }}>

          <Grid item xs={12} lg={4}>
  <SidebarSection>
    <SectionTitle>Recent Projects</SectionTitle>
    <SectionContent>
      <a href="/lifeschool/1" style={{ textDecoration: 'none', color: ' #d40032' }}>Unique LifeSchool Programme</a>
    </SectionContent>
    <SectionContent>
      <a href="/gudalur/1" style={{ textDecoration: 'none', color: ' #d40032' }}>From Floor to Bench: Gudalur</a>
    </SectionContent>
    <SectionContent>
      <a href="/tailoring/1" style={{ textDecoration: 'none', color: ' #d40032' }}>Tailoring Center:Stitching Future</a>
    </SectionContent>
  </SidebarSection>
</Grid>
<Grid item xs={12} lg={4}>
<SidebarSection>
    <SectionTitle>Get Involved</SectionTitle>
    <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
      Wany to make a difference? Check out our programs and see how you can help.
    </Typography>
    <DonateButton fullWidth      >Join Us</DonateButton>
  </SidebarSection>
</Grid>

<Grid item xs={12} lg={4}>
  <SidebarSection>
    <SectionTitle>Support Our Cause</SectionTitle>
    <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
      Your donations help us continue our mission to improve education for underprivileged children.
    </Typography>
    <DonateButton fullWidth     onClick={scrollToDonate} >Donate</DonateButton>
  </SidebarSection>
</Grid>
</Grid>
        </Grid>
      </Container>

      <Footer />
    </Box>
  );
};

export default Lifeschooldetail;
