import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, CardActions, Button, IconButton, useTheme, useMediaQuery, TextField, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import { PostAdd, EventNote, PhotoLibrary, Edit, Delete, ArrowForward, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Snackbar } from '@mui/material';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';

import * as AdminServices from '../Services/AdminServices';

const HomeContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#f8f9fa',
  minHeight: '100vh',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: '#302c51',
  marginBottom: theme.spacing(4),
  fontFamily: "'Montserrat', sans-serif",
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-10px',
    left: '0',
    width: '60px',
    height: '4px',
    backgroundColor: '#d40032 ',
  },
}));

const AdminCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: '#fff',
  boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  overflow: 'hidden',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 30px rgba(0,0,0,0.15)',
  },
}));

const CardIcon = styled(Box)(({ theme }) => ({
  fontSize: '3rem',
  color: '#d40032 ',
  marginBottom: theme.spacing(2),
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const ListContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(3),
  boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(4),
}));

const ListItemImage = styled('img')({
  width: '60px',
  height: '60px',
  marginRight: '15px',
  borderRadius: '8px',
  objectFit: 'cover',
});



const adminSections = [
  { title: 'Blog Posts', icon: <PostAdd />, section: 'posts' },
  { title: 'Events', icon: <EventNote />, section: 'events' },
  { title: 'Gallery', icon: <PhotoLibrary />, section: 'gallery' },
];

const AdminHomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const [blogPosts, setBlogPosts] = useState([]);
  const [events, setEvents] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

  const [formData, setFormData] = useState({
    title: '',
    subheading: '',
    category: '',
    content: '',
    author: '',
    image: '',
  });

  const fetchBlogPosts = async () => {
    try {
      const response = await AdminServices.getAllBlogs();
      if (response.data && response.data.success && Array.isArray(response.data.blogs)) {
        setBlogPosts(response.data.blogs);
      } else {
        console.error('Unexpected API response structure:', response.data);
        setBlogPosts([]);
      }
    } catch (error) {
      console.error('Error fetching blog posts:', error);
      setBlogPosts([]);
    }
  };

  const fetchEvents = async () => {
    try {
      const response = await AdminServices.getAllEvents();
      if (response.data && response.data.success && Array.isArray(response.data.events)) {
        setEvents(response.data.events);
      } else {
        console.error('Unexpected API response structure:', response.data);
        setEvents([]);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
      setEvents([]);
    }
  };

  useEffect(() => {
    fetchBlogPosts();
    fetchEvents();
  }, []);

  const handleDeleteConfirmation = (item) => {
    setItemToDelete(item);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      try {
        if (itemToDelete.type === 'blog') {
          await AdminServices.deleteBlog(itemToDelete._id);
          fetchBlogPosts();
        } else if (itemToDelete.type === 'event') {
          await AdminServices.deleteEvent(itemToDelete._id);
          fetchEvents();
        }
        setShowDeleteSuccess(true);
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    }
    handleCloseDialog();
  };
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  const handleLogout = () => {
    setOpenLogoutDialog(true);
  };
  
  const confirmLogout = async () => {
    try {
      const response = await AdminServices.logout();
      if (response.data.success) {
        sessionStorage.removeItem("authtoken"); // Clear token
        navigate('/login'); // Redirect to login page
      }
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setOpenLogoutDialog(false);
    }
  };






  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
    setOpenDialog(false);
    setItemToDelete(null);
    setCurrentItem(null);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredBlogs = blogPosts.filter(blog =>
    blog.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredEvents = events.filter(event =>
    event.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleEditEvent = (item) => {
    setCurrentItem(item);
    setFormData({
      title: item.title,
      subheading: item.subheading || '',
      category: item.category || '',
      content: item.content || '',
      author: item.author || '',
      image: item.imagePath?.url || '',
    });
    
    // Navigate to the admin event page for editing
    navigate('/adminEvent', { state: { event: item } });
  
    setOpenDialog(true); // Open the dialog if necessary (optional)
  };
  

  const handleEditBlog = (item) => {
    setCurrentItem(item);
    setFormData({
      title: item.title,
      subheading: item.subheading || '',
      category: item.category || '',
      content: item.content || '',
      author: item.author || '',
      image: item.imagePath?.url || '',
    });
    
    // Navigate to the admin event page for editing
    navigate('/adminBlog', { state: { blog: item } });
  
    setOpenDialog(true); // Open the dialog if necessary (optional)
  };






  const handleSaveChanges = async () => {
    try {
      if (currentItem) {
        if (currentItem.type === 'blog') {
          await AdminServices.updateBlog(currentItem._id, formData);
          await fetchBlogPosts();
        } else if (currentItem.type === 'event') {
          await AdminServices.updateEvent(currentItem._id, formData);
          await fetchEvents();
        }
        setCurrentItem(null);
      }
    } catch (error) {
      console.error('Error updating item:', error);
    }
    handleCloseDialog();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCardClick = (section) => {
    const routes = {
      posts: '/adminBlog',
      events: '/adminEvent',
      gallery: '/adminGallery',
    };
    navigate(routes[section] || '/');
  };

  const renderBlogItem = (blog) => (
    <Box key={blog._id} display="flex" justifyContent="space-between" alignItems="center" padding={2} borderBottom="1px solid #ddd">
      <Box display="flex" alignItems="center">
        <ListItemImage src={blog.imagePath?.url || ''} alt={blog.title} />
        <Typography variant="body1" style={{ fontWeight: 500 }}>{blog.title}</Typography>
      </Box>
      <Box>
      <IconButton onClick={() => handleEditBlog(blog)} style={{ color: '#302c51' }}>
  <Edit style={{ fontSize: '1rem' }} />
</IconButton>
        <IconButton onClick={() => handleDeleteConfirmation({ ...blog, type: 'blog' })} style={{ color: '#d40032 ' }}>
          <Delete style={{ fontSize: '1rem' }} />
        </IconButton>
      </Box>
    </Box>
  );

  const renderEventItem = (event) => (
    <Box key={event._id} display="flex" justifyContent="space-between" alignItems="center" padding={2} borderBottom="1px solid #ddd">
      <Box display="flex" alignItems="center">
        <ListItemImage src={event.imagePath?.url || ''} alt={event.title} />
        <Typography variant="body1" style={{ fontWeight: 500 }}>{event.title}</Typography>
      </Box>
      <Box>
      <IconButton onClick={() => handleEditEvent(event)} style={{ color: '#302c51' }}>
  <Edit style={{ fontSize: '1rem' }} />
</IconButton>

        <IconButton onClick={() => handleDeleteConfirmation({ ...event, type: 'event' })} style={{ color: '#d40032 ' }}>
          <Delete style={{ fontSize: '1rem' }} />
        </IconButton>
      </Box>
    </Box>
  );

  const renderAdminCard = ({ title, icon, section }) => (
    <Grid item xs={12} sm={6} md={4} key={section}>
      <AdminCard onClick={() => handleCardClick(section)}>
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <CardIcon>{icon}</CardIcon>
          <Typography variant="h5" sx={{ mb: 1, fontFamily: "'Montserrat', sans-serif", fontWeight: 600 }}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Manage and organize your {title.toLowerCase()}.
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
          <Button 
            size="small" 
            endIcon={<ArrowForward />} 
            sx={{ 
              color: '#302c51',
              '&:hover': { 
                backgroundColor: 'rgba(48, 44, 81, 0.08)',
              },
            }}
          >
            Create New
          </Button>
        </CardActions>
      </AdminCard>
    </Grid>
  );

  const renderDialogContent = () => {
    return (
      <>
        {['Title', 'Subheading', 'Category', 'Content', 'Author', 'Image URL'].map((field) => (
          <TextField
            key={field}
            margin="dense"
            label={field}
            type="text"
            fullWidth
            variant="outlined"
            name={field.toLowerCase().replace(" ", "")}
            value={formData[field.toLowerCase().replace(" ", "")]}
            onChange={handleInputChange}
            multiline={field === 'Content'}
            rows={field === 'Content' ? 4 : 1}
          />
        ))}
      </>
    );
  };

  return (
    <HomeContainer>
      <Container maxWidth="lg">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <SectionTitle variant="h2">Admin Dashboard</SectionTitle>
          <IconButton onClick={handleLogout} style={{ color: '#d40032 ' ,fontSize:'0.9rem' }}>
           Logout  <PowerSettingsNew />
          </IconButton>
        </Box>


        <Grid container spacing={4}>
          {adminSections.map(renderAdminCard)}
        </Grid>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <ListContainer>
              <Typography variant="h6" sx={{ fontFamily: "'Montserrat', sans-serif", fontWeight: 600 }}>Blog Posts</Typography>
              {filteredBlogs.length > 0 ? (
                filteredBlogs.map(renderBlogItem)
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No blog posts found.
                </Typography>
              )}
            </ListContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <ListContainer>
              <Typography variant="h6" sx={{ fontFamily: "'Montserrat', sans-serif", fontWeight: 600 }}>Events</Typography>
              {filteredEvents.length > 0 ? (
                filteredEvents.map(renderEventItem)
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No events found.
                </Typography>
              )}
            </ListContainer>
          </Grid>
        </Grid>
      </Container>

      <Dialog open={openDeleteDialog} onClose={handleCloseDialog}>
        <DialogTitle>Are you sure you want to delete this item?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

     


      <Dialog open={openLogoutDialog} onClose={() => setOpenLogoutDialog(false)}>
          <DialogTitle>Are you sure you want to logout?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setOpenLogoutDialog(false)} color="primary">Cancel</Button>
            <Button onClick={confirmLogout} color="secondary">Logout</Button>
          </DialogActions>
        </Dialog>
      <Snackbar
        open={showDeleteSuccess}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message="Item deleted successfully"
        onClose={() => setShowDeleteSuccess(false)}
      />
    </HomeContainer>
  );
};

export default AdminHomePage