import React from 'react';

function CancellationRefundPolicy() {
    return (
        <div style={{ margin: 20 }}>
            <h2>Cancellation and Refund Policy</h2>
            <p className='footer-text'>
                This Cancellation and Refund Policy outlines the terms under which you may cancel your order and request a refund. By using our service, you agree to the terms outlined in this policy.
            </p>

            <h3>Order Cancellation</h3>
            <p className='footer-text'>
                You may cancel your order within 24 hours of placing it. To cancel your order, please contact us at <a href="mailto:rayoflovedf@gmail.com">rayoflovedf@gmail.com</a> with your order details.
            </p>

            <h3>Refund Eligibility</h3>
            <p className='footer-text'>
                To be eligible for a refund, the following conditions must be met:
            </p>
            <ul>
                <li>
                    <p className='footer-text'>The request must be made within 30 days of receiving the product.</p>
                </li>
                <li>
                    <p className='footer-text'>The product must be unused and in its original packaging.</p>
                </li>
                <li>
                    <p className='footer-text'>Proof of purchase is required.</p>
                </li>
            </ul>

            <h3>How to Request a Refund</h3>
            <p className='footer-text'>
                To request a refund, please follow these steps:
            </p>
            <ol>
                <li>Contact us at <a href="mailto:rayoflovedf@gmail.com">rayoflovedf@gmail.com</a> with your order number.</li>
                <li>Provide a brief explanation of the reason for the refund.</li>
                <li>Return the product to the address provided in our response.</li>
            </ol>

            <h3>Refund Process</h3>
            <p className='footer-text'>
                Once we receive your returned item, we will inspect it and notify you of the status of your refund. If approved, your refund will be processed, and a credit will automatically be applied to your original method of payment within 1-7 working days.
            </p>

            <h3>Exclusions</h3>
            <p className='footer-text'>
                The following items are not eligible for refund:
            </p>
            <ul>
                <li>Gift cards</li>
                <li>Downloadable software products</li>
                <li>Opened personal care products</li>
            </ul>

            <h3>Changes to Cancellation and Refund Policy</h3>
            <p className='footer-text'>
                We may update our Cancellation and Refund Policy from time to time. We will notify you of any changes by updating this page.
            </p>

            <h3>Contact Us</h3>
            <p className='footer-text'>
                If you have any questions about our Cancellation and Refund Policy, you can contact us:
            </p>
            <ul>
                <li>By email: <a href="mailto:rayoflovedf@gmail.com">rayoflovedf@gmail.com</a></li>
            </ul>
        </div>
    );
}

export default CancellationRefundPolicy;
