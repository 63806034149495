
import React from 'react';
import Slide from './Slide';
import About from './About';
import Project from './Project';
import Donation from './Donation';
import Footer from './Footer';
import DonateUs from './DonateUs';
import Testimonials from './Testimonials';
import Gallery from './Gallery';
import Events from './Events';
import BlogSection from './BlogSection';

const HomePage = () => {
  return (
    <>
       <Slide />
      <About />
       <Donation />
      <Project />
       <Events />
      <DonateUs /> 
      <Testimonials /> 
      {/* <BlogSection />  */}
     <Gallery />
      <Footer />     
    </>
  );
};

export default HomePage;
