import React, { useEffect,useCallback } from 'react';
import { Box, Container, Typography, Grid, IconButton, Button, useTheme, useMediaQuery } from '@mui/material';
import { ArrowBack, VisibilityOutlined, ChatBubbleOutline } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom'; 
import heroImage from './images/background/detailabout.png'; 
import blogImage from './images/about/life3.jpeg';
import Footer from './Footer';
import goat1 from './images/about/goat1.jpeg';
import goat2 from './images/about/goat3.jpeg';
import goat4 from './images/about/goat4.jpeg';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));


const SidebarSection = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  boxShadow: '0 6px 24px rgba(0,0,0,0.1)',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)( {
  fontSize: '1.7rem',
  fontWeight: 600,
  marginBottom: '1rem',
});
const DonateButton = styled(Button)( {
  backgroundColor: ' #d40032',
  color: '#fff',
  fontWeight: 'bold',
  borderRadius: '24px',
  padding: '10px 20px',
  marginTop: '16px',
  '&:hover': {
    backgroundColor: '#b30029',
  },
});
const SectionContent = styled(Typography)( {
  fontSize: '1rem',
  color: '#555',
  marginBottom: '1rem',
});
const Lifeschooldetail = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const scrollToDonate = useCallback(() => {
    navigate('/donateUs');
    setTimeout(() => {
        const donateSection = document.getElementById('donate-us-section');
        if (donateSection) {
            donateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } 
    }, 100);
}, [navigate]);
  
  const blog = {
    title: 'Empowering Women Through Sustainable Goat Rearing: Ray of Love Development Foundation Initiative',


    date: 'May 4, 2024',
    author: 'Shajan John Edakkad',
    views: 123,
    comments: 45,
    content: `
        <p style="text-align: justify; line-height: 1.5;">At Ray of Love Development Foundation, our mission to uplift communities is often realised through empowering the hands that build them: women. Our goat rearing project is designed to offer women an opportunity to create sustainable, independent livelihoods, fostering economic resilience and self-sufficiency.
This initiative focuses on providing women with the skills, resources, and ongoing support they need to succeed in goat farming. Starting with practical training on animal care, breeding, and nutrition, participants gain the knowledge to raise healthy, productive livestock. With the guidance of experienced trainers, these women learn to manage goat herds that can become a source of steady income for their families.

</p>
      <img src="${goat4}" alt="Blog Detail" style="width: 100%; margin: 20px 0;" />


     
  <p style="text-align: justify; line-height: 1.5;">  What makes this project truly impactful is its focus on economic independence. Goat rearing provides multiple income streams, from the sale of milk and meat to breeding goats for resale. This newfound financial stability allows women to support their households, invest in their children's education, and contribute to their communities.
Additionally, goat farming is well-suited to local resources, making it both affordable and sustainable. By harnessing locally available materials and traditional methods, women can manage their farms with minimal financial input, enhancing the project’s long-term sustainability.

</p>
 <div class="detailimage-container">
    <img src="${goat1}" alt="Blog Detail" class="life-image" />
    <img src="${goat2}" alt="Blog Detail" class="life-image" />
</div>

</b></p>
  <p style="text-align: justify; line-height: 1.5;">The project goes beyond economic empowerment, fostering a sense of community as the women share resources, insights, and encouragement with one another. This network strengthens community ties and provides ongoing support, building resilience within the community itself.
Through our goat rearing project, we’re witnessing remarkable stories of transformation—of women stepping into roles as breadwinners, leaders, and agents of change. At Ray of Love, we believe that empowering women with tools for sustainable livelihoods lays the foundation for stronger families and, ultimately, a thriving community.

</p>




 
    `,
  };

  return (
    <Box sx={{ backgroundColor: '#f8f8f8' }}>
      {/* Hero Section */}
      <HeroSection>
        <HeroContent>
          <Typography variant="h5" sx={{ fontWeight: 600, fontSize: { xs: '1.5rem', md: '2.5rem' }, marginBottom: 2 }}>
          Goat Rearing to Empower Women
          </Typography>
          {/* <Typography variant="h7" sx={{ fontWeight: 400, fontSize: { xs: '1rem', md: '1.3rem' }, marginBottom: 4 }}>
          Let’s rock and sing, let’s grow together!
          </Typography> */}
        </HeroContent>
      </HeroSection>
      



        {/* Blog Content Section */}
        <Container>
        <Grid container spacing={3} sx={{ my: 4 }}>
          <Grid item xs={12} md={12} sx={{ mb: 4 }}  >
            <Box sx={{ backgroundColor: 'white', borderRadius: 2, boxShadow: '0 4px 20px rgba(0,0,0,0.1)', p: 4 }}>
              <Typography variant="caption" sx={{ color: '#888', mb: 1 }}>
                {blog.date}
              </Typography>
              <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 , fontSize: { xs: '1.5rem', md: '2rem' },}}>
                {blog.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#555', mb: 2 }}>
                by {blog.author}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                  <VisibilityOutlined sx={{ fontSize: 20, color: '#d40032 ' }} />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>{blog.views} views</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ChatBubbleOutline sx={{ fontSize: 20, color: '#d40032 ' }} />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>{blog.comments} comments</Typography>
                </Box>
              </Box>
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            </Box>
          </Grid>

          {/* Sidebar Section */}
          <Grid container spacing={2} style={{ marginLeft: 4 }}>

          <Grid item xs={12} lg={4}>
  <SidebarSection>
    <SectionTitle>Recent Projects</SectionTitle>
    <SectionContent>
      <a href="/lifeschool/1" style={{ textDecoration: 'none', color: ' #d40032' }}>Unique LifeSchool Programme</a>
    </SectionContent>
    <SectionContent>
      <a href="/gudalur/1" style={{ textDecoration: 'none', color: ' #d40032' }}>From Floor to Bench: Gudalur</a>
    </SectionContent>
    <SectionContent>
      <a href="/tailoring/1" style={{ textDecoration: 'none', color: ' #d40032' }}>Tailoring Center:Stitching Future</a>
    </SectionContent>
  </SidebarSection>
</Grid>
<Grid item xs={12} lg={4}>
<SidebarSection>
    <SectionTitle>Get Involved</SectionTitle>
    <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
      Wany to make a difference? Check out our programs and see how you can help.
    </Typography>
    <DonateButton fullWidth      >Join Us</DonateButton>
  </SidebarSection>
</Grid>

<Grid item xs={12} lg={4}>
  <SidebarSection>
    <SectionTitle>Support Our Cause</SectionTitle>
    <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
      Your donations help us continue our mission to improve education for underprivileged children.
    </Typography>
    <DonateButton fullWidth     onClick={scrollToDonate} >Donate</DonateButton>
  </SidebarSection>
</Grid>
</Grid>
        </Grid>
      </Container>

      <Footer />
    </Box>
  );
};

export default Lifeschooldetail;
