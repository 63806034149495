import React, { useState } from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, CardActions, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { Edit, Delete, Add } from '@mui/icons-material';

import project1Image from './images/admin/projects.jpg';
import project2Image from './images/admin/projects.jpg';

const HomeContainer = styled(({ children, ...otherProps }) => (
  <Box {...otherProps}>{children}</Box>
))(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#f8f9fa',
  minHeight: '100vh',
}));


const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: '#302c51',
  marginBottom: theme.spacing(4),
  fontFamily: "'Montserrat', sans-serif",
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-10px',
    left: '0',
    width: '60px',
    height: '4px',
    backgroundColor: '#d40032 ',
  },
}));

const AdminCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: '#fff',
  boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  overflow: 'hidden',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 30px rgba(0,0,0,0.15)',
  },
}));

const CardImage = styled('img')({
  width: '100%',
  height: '150px',
  objectFit: 'cover',
  borderBottom: '1px solid #ddd',
});

const projects = [
  { id: 1, title: 'Charity Work', description: 'Description of Charity Work', image: project1Image },
  { id: 2, title: 'Education', description: 'Description of Education', image: project2Image },
];

const AdminProjectsPage = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectTitle, setProjectTitle] = useState('');
  const [projectDescription, setProjectDescription] = useState('');

  const handleOpenDialog = (project) => {
    setSelectedProject(project);
    setProjectTitle(project ? project.title : '');
    setProjectDescription(project ? project.description : '');
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedProject(null);
    setProjectTitle('');
    setProjectDescription('');
  };

  const handleSaveProject = () => {
    console.log('Saved:', projectTitle, projectDescription);
    handleCloseDialog();
  };

  const handleDeleteProject = (id) => {
    console.log('Deleted project with id:', id);
  };

  const renderProjectCard = (project) => (
    <Grid item xs={12} sm={6} md={4} key={project.id}>
      <AdminCard>
        <CardImage src={project.image} alt={project.title} />
        <CardContent>
          <Typography variant="h6" gutterBottom>{project.title}</Typography>
          <Typography variant="body2" color="textSecondary">{project.description}</Typography>
        </CardContent>
        <CardActions>
          <IconButton sx={{ color: '#302c51' }}  onClick={() => handleOpenDialog(project)}>
            <Edit />
          </IconButton>
          <IconButton sx={{ color: '#d40032 ' }} onClick={() => handleDeleteProject(project.id)}>
            <Delete />
          </IconButton>
        </CardActions>
      </AdminCard>
    </Grid>
  );

  return (
    <HomeContainer>
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <SectionTitle variant="h2">Manage Projects</SectionTitle>
          <Button
            variant="contained"
            startIcon={<Add />}
            sx={{ backgroundColor: '#d40032 ', '&:hover': { backgroundColor: '#b80029' } }}
            onClick={() => handleOpenDialog(null)}
          >
            Add New Project
          </Button>
        </Box>

        <Grid container spacing={4}>
          {projects.map(renderProjectCard)}
        </Grid>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{selectedProject ? 'Edit Project' : 'Add New Project'}</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Project Title"
              fullWidth
              value={projectTitle}
              onChange={(e) => setProjectTitle(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              margin="dense"
              label="Project Description"
              fullWidth
              multiline
              rows={4}
              value={projectDescription}
              onChange={(e) => setProjectDescription(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: '#302c51' }} onClick={handleCloseDialog}>Cancel</Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#d40032 ', '&:hover': { backgroundColor: '#b80029' } }}
              onClick={handleSaveProject}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </HomeContainer>
  );
};

export default AdminProjectsPage;
