import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './App.css';

// Import your components
import Login from './Components/Auth/Login';
import Register from './Components/Auth/Register';
import ForgetPassword from './Components/Auth/ForgetPassword';
import Verify from './Components/Auth/Verify';
import Reset from './Components/Auth/Reset';
import { getCurrentUser } from './Services/AdminServices';
import { finishLoadingUser } from './Components/redux/slices/userLoadingSlice';
import { setUser } from './Components/redux/slices/userSlice';
import Header from './Components/Header';
import HomePage from './Components/HomePage';
import Contact from './Components/Contact';
import DetailAbout from './Components/DetailAbout';
import EventPage from './Components/EventPage';
import BlogPage from './Components/BlogPage';
import BlogDetail from './Components/BlogDetail';
import EventDetail from './Components/EventDetail';
import GalleryDetail from './Components/GalleryDetail';
import DonatePage from './Components/DonatePage';
import BlogPostAdmin from './Components/BlogPostAdmin';
import EventPostAdmin from './Components/EventPostAdmin';
import AdminGallery from './Components/AdminGallery';
import EditItemPage from './Components/EditItemPage';
import AdminProjectsPage from './Components/AdminProjectsPage';
import DonateUs from './Components/DonateUs';
import Terms from './Components/Terms';
import Refunds from './Components/Refunds';
import Shipping from './Components/Shipping';
import Privacy from './Components/Privacy';
import AdminHomePage from './Components/AdminHomePage';
import Projectpage from './Components/Projectpage';
import Lifeschooldetail from './Components/Lifeschooldetail';

import Gudalurdetail from './Components/Gudalurdetail';
import Goatdetail from './Components/Goatdetail';
import Tailoringdetail from './Components/Tailoringdetail';
import Event1 from './Components/Event1';
import Event4 from './Components/Event4';
import Event2 from './Components/Event2';
import Event3 from './Components/Event3';
function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = !!sessionStorage.getItem("authtoken");

  useEffect(() => {
    if (isLoggedIn) {
      getCurrentUser()
        .then((response) => {
          dispatch(setUser(response?.data));
          dispatch(finishLoadingUser());
        })
        .catch((err) => {
          sessionStorage.removeItem("authtoken");
          dispatch(finishLoadingUser());
        });
    } else {
      dispatch(finishLoadingUser());
    }
  }, [dispatch, isLoggedIn]);

  // Define routes without a header
  const noHeaderRoutes = [
    '/login',
    '/register',
    '/reset',
    '/forget',
    '/verify/:id',
    '/admin',
    '/adminBlog',
    '/adminEvent',
    '/adminGallery',
    '/edititem',
    '/adminProjects',
  ];

  return (
    <>
      {!noHeaderRoutes.some(route => location.pathname.match(new RegExp(route))) && <Header />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contactPage" element={<Contact />} />
        <Route path="/detailAbout" element={<DetailAbout />} />
        <Route path="/eventsPage" element={<EventPage />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/projects" element={<Projectpage />} />
        <Route path="/BlogDetail/:id" element={<BlogDetail />} />
        <Route path="/tailoring/:id" element={<Tailoringdetail />} />
        <Route path="/lifeschool/:id" element={<Lifeschooldetail />} />
        <Route path="/gudalur/:id" element={<Gudalurdetail />} />
        <Route path="/goat/:id" element={<Goatdetail />} />

        <Route path="/event1/:id" element={<Event1 />} />
        <Route path="/event2/:id" element={<Event2 />} />
        <Route path="/event3/:id" element={<Event3 />} />
        <Route path="/event4/:id" element={<Event4 />} />



        <Route path="/eventDetail/:id" element={<EventDetail />} />
        <Route path="/galleryPage" element={<GalleryDetail />} />
        <Route path="/donatePage" element={<DonatePage />} />
        <Route path="/donateUs" element={<DonateUs />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/refunds" element={<Refunds />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/forget" element={<ForgetPassword />} />
        <Route path="/verify/:id" element={<Verify />} />
        
        {/* Protected Admin Routes */}
        <Route path="/admin" element={isLoggedIn ? <AdminHomePage /> : <Navigate to="/login" />} />
        <Route path="/adminBlog" element={isLoggedIn ? <BlogPostAdmin /> : <Navigate to="/login" />} />
        <Route path="/adminEvent" element={isLoggedIn ? <EventPostAdmin /> : <Navigate to="/login" />} />
        <Route path="/adminGallery" element={isLoggedIn ? <AdminGallery /> : <Navigate to="/login" />} />
        <Route path="/edititem" element={isLoggedIn ? <EditItemPage /> : <Navigate to="/login" />} />
        <Route path="/adminProjects" element={isLoggedIn ? <AdminProjectsPage /> : <Navigate to="/login" />} />
      </Routes>
    </>
  );
}

export default App;
