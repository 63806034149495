import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  TextField, 
  Button, 
  InputAdornment, 
  IconButton,
  Alert,
  Snackbar,
  CircularProgress
} from '@mui/material';
import { AttachFile, Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import { postEvent, updateEvent } from '../Services/AdminServices';

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: '#302c51',
  textAlign: 'center',
  marginBottom: theme.spacing(6),
}));

const FormField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .Mui-error': {
    color: 'red', // Set the error message color to red
  },
}));

const ImagePreview = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: 200,
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const EventPostAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const existingEvent = location.state?.event;

  const [formData, setFormData] = useState({
    title: existingEvent?.title || '',
    subheading: existingEvent?.subheading || '',
    category: existingEvent?.category || '',
    author: existingEvent?.author || '',
    content: existingEvent?.content || '',
  });

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(existingEvent?.imagePath?.url || '');
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [errors, setErrors] = useState({});

  const validateImage = (file) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!file) {
      return existingEvent ? true : 'Image is required';
    }

    if (!validTypes.includes(file.type)) {
      return 'Invalid file type. Please upload a JPEG, PNG, GIF, or WebP image.';
    }

    if (file.size > maxSize) {
      return 'File size too large. Maximum size is 5MB.';
    }

    return true;
  };

  const validateForm = () => {
    const newErrors = {};

    Object.keys(formData).forEach(key => {
      if (!formData[key].trim()) {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
      }
    });

    const imageValidation = validateImage(image);
    if (imageValidation !== true) {
      newErrors.image = imageValidation;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageValidation = validateImage(file);
      if (imageValidation === true) {
        setImage(file);
        setImagePreview(URL.createObjectURL(file));
        setErrors(prev => ({ ...prev, image: '' }));
      } else {
        setErrors(prev => ({ ...prev, image: imageValidation }));
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      setSnackbar({
        open: true,
        message: 'Please fix the errors before submitting',
        severity: 'error'
      });
      return;
    }

    setIsLoading(true);

    const submitFormData = new FormData();
    Object.keys(formData).forEach(key => {
      submitFormData.append(key, formData[key]);
    });
    if (image) submitFormData.append('image', image);

    try {
      const response = existingEvent 
        ? await updateEvent(existingEvent._id, submitFormData) 
        : await postEvent(submitFormData);

      if (response.status === 200 || response.status === 201) {
        setSnackbar({
          open: true,
          message: `Event ${existingEvent ? 'updated' : 'created'} successfully!`,
          severity: 'success'
        });
        // Reduce the time for navigating after a successful event creation
        setTimeout(() => navigate(-1), 6000);
      }
    } catch (error) {
      console.error('Error details:', error.response ? error.response.data : error.message);
      setSnackbar({
        open: true,
        message: `Error ${existingEvent ? 'updating' : 'creating'} event: ${error.message}`,
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure you want to discard your changes?")) {
      navigate(-1);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#f8f8f8', py: 10 }}>
      <Container maxWidth="lg">
        <Button 
          variant="outlined" 
          sx={{ mb: 4, textTransform: 'none', color: "#302c51", borderColor: "#302c51", '&:hover': { backgroundColor: '#f0f0f0' }}} 
          onClick={handleCancel}
        >
          Go Back
        </Button>
        <SectionTitle variant="h2">
          {existingEvent ? 'Edit Event' : 'Create a New Event'}
        </SectionTitle>
        
        <Box component="form" onSubmit={handleSubmit}>
          {Object.keys(formData).map((field) => (
            <FormField
              key={field}
              fullWidth
              name={field}
              label={field.charAt(0).toUpperCase() + field.slice(1)}
              variant="outlined"
              value={formData[field]}
              onChange={handleInputChange}
              error={!!errors[field]}
              helperText={errors[field]}
              multiline={field === 'content'}
              rows={field === 'content' ? 6 : 1}
              required
            />
          ))}

          <FormField
            fullWidth
            label="Image"
            variant="outlined"
            error={!!errors.image}
            helperText={errors.image}
            value={image ? image.name : ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton component="label">
                    <AttachFile />
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            required={!existingEvent}
            readOnly
          />

          {imagePreview && <ImagePreview src={imagePreview} alt="Image preview" />}

          <Box sx={{ textAlign: 'center', mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: 600,
                px: 4,
                py: 1.5,
                color: "#302c51",
                borderColor: "#302c51",
                '&:hover': { backgroundColor: '#f0f0f0' },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              sx={{
                backgroundColor: '#d40032',
                '&:hover': { backgroundColor: '#b8002d' },
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: 600,
                px: 4,
                py: 1.5,
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                existingEvent ? 'Update Event' : 'Create Event'
              )}
            </Button>
          </Box>
        </Box>

        <Snackbar
  open={snackbar.open}
  autoHideDuration={5000}
  onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
  anchorOrigin={{ vertical: 'top', horizontal: 'top' }}
>
  <Alert
    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
    severity={snackbar.severity}
    sx={{
      width: '25%',
      backgroundColor: 'white', // Set background color to white
      color: '#d40032',              // Set text color to red
      '& .MuiSvgIcon-root': {    // Target the icon
        color: '#d40032',            // Set icon color to red
      }
    }}
  >
    {snackbar.message}
  </Alert>
</Snackbar>


        {isLoading && (
          <LoadingOverlay>
            <CircularProgress />
          </LoadingOverlay>
        )}
      </Container>
    </Box>
  );
};

export default EventPostAdmin;
