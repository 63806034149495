import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Box, Container, Typography, Grid } from '@mui/material';
import portfolio1 from './images/about/portfolio1.jpg';
import portfolio2 from './images/about/portfolio2.jpg';
import portfolio3 from './images/about/portfolio3.jpg';
import portfolio4 from './images/about/portfolio4.jpg';
import portfolio5 from './images/about/portfolio5.jpg';

const galleryItems = [
  { id: 1, image: portfolio1 },
  { id: 2, image: portfolio2 },
  { id: 3, image: portfolio3 },
  { id: 4, image: portfolio4 },
  { id: 5, image: portfolio5 },
];

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % galleryItems.length);
    }, 3000); 

    return () => clearInterval(interval);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + galleryItems.length) % galleryItems.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % galleryItems.length);
  };

  const visibleItems = [
    galleryItems[(currentIndex + 0) % galleryItems.length],
    galleryItems[(currentIndex + 1) % galleryItems.length],
    galleryItems[(currentIndex + 2) % galleryItems.length],
    galleryItems[(currentIndex + 3) % galleryItems.length],
  ];

  return (
    <Box sx={{ backgroundColor: '#d40032', color: 'white', py: 4 }}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={3}>
            <Typography
              variant="h6"
              sx={{
                textTransform: 'uppercase',
                mb: 1,
                fontFamily: 'sans-serif',
                fontSize: '16px',
                fontWeight: 600,
              }}
            >
              Our Gallery
            </Typography>
            <Typography
              variant="h2"
              sx={{ fontWeight: 'bold', mb: 3, fontSize: '36px' }}
            >
              Gallery of our works
            </Typography>
            <Box sx={{ mt: 2 }}>
              <a
                href="/galleryPage"
                style={{
                  textDecoration: 'none',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '12px',
                  textTransform: 'uppercase',
                }}
              >
                <ChevronRight size={14} style={{ marginRight: '8px' }} />
                More from Gallery
              </a>
            </Box>
          </Grid>

          <Grid item xs={12} md={9} sx={{ position: 'relative' }}>
            <Grid container spacing={2}>
              {visibleItems.map((item) => (
                <Grid item xs={6} md={3} key={item.id}>
                  <Box
                    component="img"
                    src={item.image}
                    alt={item.title}
                    sx={{
                      width: '100%',
                      borderRadius: '4px',
                      height: 'auto',
                    }}
                  />
                </Grid>
              ))}
            </Grid>

            {/* Previous Button */}
            <Box
              component="button"
              onClick={handlePrev}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '10px', // Adjusted positioning
                transform: 'translateY(-50%)',
                backgroundColor: 'white',
                color: 'black',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <ChevronLeft size={24} />
            </Box>

            {/* Next Button */}
            <Box
              component="button"
              onClick={handleNext}
              sx={{
                position: 'absolute',
                top: '50%',
                right: '10px', // Adjusted positioning
                transform: 'translateY(-50%)',
                backgroundColor: 'white',
                color: 'black',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <ChevronRight size={24} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Gallery;
