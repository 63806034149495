import React, { useEffect,useCallback } from 'react';
import { Box, Container, Typography, Grid, IconButton, Button, useTheme, useMediaQuery } from '@mui/material';
import { ArrowBack, VisibilityOutlined, ChatBubbleOutline } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom'; 
import heroImage from './images/background/detailabout.png'; 
import event4 from './images/about/event4.jpeg';
import Footer from './Footer';

import cook2 from './images/about/cook2.jpg';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));


const SidebarSection = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  boxShadow: '0 6px 24px rgba(0,0,0,0.1)',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)( {
  fontSize: '1.7rem',
  fontWeight: 600,
  marginBottom: '1rem',
});
const DonateButton = styled(Button)( {
  backgroundColor: ' #d40032',
  color: '#fff',
  fontWeight: 'bold',
  borderRadius: '24px',
  padding: '10px 20px',
  marginTop: '16px',
  '&:hover': {
    backgroundColor: '#b30029',
  },
});
const SectionContent = styled(Typography)( {
  fontSize: '1rem',
  color: '#555',
  marginBottom: '1rem',
});
const Lifeschooldetail = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const scrollToDonate = useCallback(() => {
    navigate('/donateUs');
    setTimeout(() => {
        const donateSection = document.getElementById('donate-us-section');
        if (donateSection) {
            donateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } 
    }, 100);
}, [navigate]);
  
  const blog = {
    title: 'Kid Cooking: Discovering Love, Sacrifice, and Unity at Life School',

    date: 'May 4, 2024',
    author: 'Shajan John Edakkad',
    views: 123,
    comments: 45,
    content: `
        <p style="text-align: justify; line-height: 1.5;"><b> "“As little chefs, they start to know,
Father’s love and Mother’s glow.”
"</b></p>
        <p style="text-align: justify; line-height: 1.5;">At Life School, there’s a special place where children get the chance to become little chefs. Anandu, Chacko, and Ramya sit around a stone stove, cooking with the heat of a fire and the warmth of tradition. The rising ash from the fireplace leaves marks on their faces, and the intense heat is almost unbearable—but they don’t mind. The lesson they’re learning is far more important than the discomfort they feel.
This is the Kids Kitchen at Life School, where children from different homes come together to cook in the most traditional way. It’s not just about preparing food; it’s about understanding life, love, and the deep bonds that hold families and communities together. As the children cook and share meals, they quickly learn the importance of unity, not just within their families but across neighbourhoods and nations.

</p>
      <div class="detailimage-container">
    <img src="${cook2}" alt="Blog Detail" class="life-image" />
    <img src="${event4}" alt="Blog Detail" class="life-image" />
</div>


    
  <p style="text-align: justify; line-height: 1.5;">  In the kitchen, children begin to understand the quiet sacrifices that go unnoticed in their everyday lives. They realise how their fathers work tirelessly to gather food and provide for their families, and how their mothers wake up before dawn to prepare meals and care for them. These actions—performed every day without complaint—are acts of love and dedication that children come to appreciate as they step into the kitchen themselves.
Sitting around the fire, cooking and learning, children experience the true meaning of home and country. They see the goodness of their surroundings and the care that keeps families united. By watching, listening, and doing, they not only learn how to cook, but they also learn the deeper values of love, sacrifice, and togetherness that will guide them as they prepare for a new tomorrow.
At Life School’s Kids Kitchen, children are more than just cooking—they are learning the timeless lessons of love and unity that will stay with them for a lifetime.

</p>





    `,
  };

  return (
    <Box sx={{ backgroundColor: '#f8f8f8' }}>
      {/* Hero Section */}
      <HeroSection>
        <HeroContent>
          <Typography variant="h5" sx={{ fontWeight: 600, fontSize: { xs: '1.5rem', md: '2.5rem' }, marginBottom: 2 }}>
          Kid Cooking: Discovering Love and Unity
          </Typography>
         
        </HeroContent>
      </HeroSection>
      



        {/* Blog Content Section */}
        <Container>
        <Grid container spacing={3} sx={{ my: 4 }}>
          <Grid item xs={12} md={12} sx={{ mb: 4 }}  >
            <Box sx={{ backgroundColor: 'white', borderRadius: 2, boxShadow: '0 4px 20px rgba(0,0,0,0.1)', p: 4 }}>
              <Typography variant="caption" sx={{ color: '#888', mb: 1 }}>
                {blog.date}
              </Typography>
              <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 , fontSize: { xs: '1.5rem', md: '2rem' },}}>
                {blog.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#555', mb: 2 }}>
                by {blog.author}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                  <VisibilityOutlined sx={{ fontSize: 20, color: '#d40032 ' }} />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>{blog.views} views</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ChatBubbleOutline sx={{ fontSize: 20, color: '#d40032 ' }} />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>{blog.comments} comments</Typography>
                </Box>
              </Box>
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            </Box>
          </Grid>

          {/* Sidebar Section */}
          <Grid container spacing={2} style={{ marginLeft: 4 }}>

          <Grid item xs={12} lg={4}>
  <SidebarSection>
    <SectionTitle>Recent Projects</SectionTitle>
    <SectionContent>
      <a href="/lifeschool/1" style={{ textDecoration: 'none', color: ' #d40032' }}>Unique LifeSchool Programme</a>
    </SectionContent>
    <SectionContent>
      <a href="/gudalur/1" style={{ textDecoration: 'none', color: ' #d40032' }}>From Floor to Bench: Gudalur</a>
    </SectionContent>
    <SectionContent>
      <a href="/tailoring/1" style={{ textDecoration: 'none', color: ' #d40032' }}>Tailoring Center:Stitching Future</a>
    </SectionContent>
  </SidebarSection>
</Grid>

<Grid item xs={12} lg={4}>
<SidebarSection>
    <SectionTitle>Get Involved</SectionTitle>
    <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
      Wany to make a difference? Check out our programs and see how you can help.
    </Typography>
    <DonateButton fullWidth      >Join Us</DonateButton>
  </SidebarSection>
</Grid>

<Grid item xs={12} lg={4}>
  <SidebarSection>
    <SectionTitle>Support Our Cause</SectionTitle>
    <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
      Your donations help us continue our mission to improve education for underprivileged children.
    </Typography>
    <DonateButton fullWidth     onClick={scrollToDonate} >Donate</DonateButton>
  </SidebarSection>
</Grid>
</Grid>
        </Grid>
      </Container>

      <Footer />
    </Box>
  );
};

export default Lifeschooldetail;
