import React, { useEffect, useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import Footer from './Footer';
import { getAllGallery } from '../Services/AdminServices'; 

const GalleryDetail = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGalleryItems = async () => {
      try {
        const response = await getAllGallery();
        if (response.data.success) {
          setGalleryItems(response.data.galleryItems);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGalleryItems();
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <>
      <Box sx={{ backgroundColor: '#f0f0f0', py: 8 }}>
        <Container maxWidth="lg">
          {/* Page Header */}
          <Box sx={{ mb: 6, textAlign: 'center' }}>
            <Typography
              variant="h4"
              sx={{
                textTransform: 'uppercase',
                color: '#d40032 ',
                fontWeight: 700,
                mb: 2,
                fontFamily: 'sans-serif',
              }}
            >
              Our Gallery
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#555',
                fontSize: '18px',
                maxWidth: '600px',
                margin: '0 auto',
                fontFamily: 'serif',
              }}
            >
              Explore the various initiatives, events, and projects we have undertaken to make a positive impact in our community and beyond.
            </Typography>
          </Box>

          {/* Masonry Grid for Gallery */}
          <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
            {galleryItems.map((item) => (
              <Box key={item._id} sx={{ position: 'relative' }}>
                <Box
                  component="img"
                  src={item.imagePath.url}
                  alt={item.title}
                  sx={{
                    width: '100%',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                />
                {/* Image Overlay */}
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '8px',
                    left: '8px',
                    right: '8px',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    color: 'white',
                    p: 1,
                    borderRadius: '4px',
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {item.title}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Masonry>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default GalleryDetail;
