import React, { useEffect,useCallback } from 'react';
import { Box, Container, Typography, Grid, IconButton, Button, useTheme, useMediaQuery } from '@mui/material';
import { ArrowBack, VisibilityOutlined, ChatBubbleOutline } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom'; 
import heroImage from './images/background/detailabout.png'; 
import blogImage from './images/about/life3.jpeg';
import Footer from './Footer';
import life2 from './images/about/life1.jpeg';
import life3 from './images/about/life2.jpeg';
import life4 from './images/about/life4.jpeg';
import life6 from './images/about/life6.jpeg';
import life5 from './images/about/life5.jpeg';
const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));


const SidebarSection = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  boxShadow: '0 6px 24px rgba(0,0,0,0.1)',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)( {
  fontSize: '1.7rem',
  fontWeight: 600,
  marginBottom: '1rem',
});
const DonateButton = styled(Button)( {
  backgroundColor: ' #d40032',
  color: '#fff',
  fontWeight: 'bold',
  borderRadius: '24px',
  padding: '10px 20px',
  marginTop: '16px',
  '&:hover': {
    backgroundColor: '#b30029',
  },
});
const SectionContent = styled(Typography)( {
  fontSize: '1rem',
  color: '#555',
  marginBottom: '1rem',
});
const Lifeschooldetail = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const scrollToDonate = useCallback(() => {
    navigate('/donateUs');
    setTimeout(() => {
        const donateSection = document.getElementById('donate-us-section');
        if (donateSection) {
            donateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } 
    }, 100);
}, [navigate]);
  
  const blog = {
    title: 'Life School: Where Dreams Take Flight and Hearts Find Strength',

    date: 'May 4, 2024',
    author: 'Shajan John Edakkad',
    views: 123,
    comments: 45,
    content: `
       <p style="text-align: justify; line-height: 1.5;"><b>"Let’s rock and sing, let’s grow together!"</b></p>
<p style="text-align: justify; line-height: 1.5;">
  At Parip UP School, a chorus of young voices echoes through the halls, filling the air with laughter, music, and an unbreakable spirit. More than a hundred children gathered for Life School, singing with a joy that could only come from hearts unburdened by fear, ready to dream, and eager to grow. These children come from Aymanam Panchayat, an area often plagued by floods and hardship, yet they arrive each day with resilience in their steps. The fields that surround them might flood, but here at Life School, the only flood is one of hope, kindness, and support. With their feet planted firmly on the ground, they look up, and Life School teaches them that it’s okay to reach for the sky.
</p>
<img src="${blogImage}" alt="Blog Detail" style="width: 100%; margin: 20px 0;" />

 <p style="text-align: justify; line-height: 1.5;">A Sanctuary for Little Dreamers</b></p>
<p style="text-align: justify; line-height: 1.5;">
  Life School isn’t just a place; it’s a sanctuary. It was created by the Ray of Love Development Foundation to be a haven for children to discover their strength, realize their worth, and find a safe place to dream without limits. Here, they learn to trust themselves again, to rebuild the confidence that life’s hardships may have shaken. These children, no matter their struggles, are learning that they are powerful beyond measure. For three magical days, the classrooms of Parip UP School come alive with stories, songs, games, and love. They’re taught that they’re not just children; they’re young leaders, capable of shaping the world around them. Through every song sung and every story shared, they’re shown that they are more than their challenges—they are resilient, hopeful, and unstoppable.
</p>

<div class="detailimage-container">
  <img src="${life2}" alt="Blog Detail" class="life-image" />
  <img src="${life3}" alt="Blog Detail" class="life-image" />
</div>

 <p style="text-align: justify; line-height: 1.5;">Agents of Change, Builders of Dreams</b></p>
<p style="text-align: justify; line-height: 1.5;">
  The heart of Life School lies in the belief that children can change the world. When given the support, tools, and love they need, they can grow into leaders who inspire and uplift others. For three beautiful days, Life School became a second home for 110 children, guided by 15 warm-hearted facilitators who treated each child like family. They shared lessons in kindness, set goals together, and painted pictures of a brighter future. As the children played and learned, they built memories they’d carry for a lifetime. They felt welcomed by the villagers, who showed them love and support, offering feasts each day to celebrate this special moment in their young lives. Every meal, every smile, and every encouraging word became a gift, showing these children they are cherished and valued.
</p>
<img src="${life4}" alt="Blog Detail" style="width: 100%; margin: 20px 0;" />

 <p style="text-align: justify; line-height: 1.5;">A House of Love, Hope, and Endless Possibility</b></p>
<p style="text-align: justify; line-height: 1.5;">
  Life School is more than a program—it’s a house of love. A place where children are encouraged to be bold, to believe that life holds more for them, and to see themselves as precious and powerful. It’s a safe space where every child is reminded that they’re not alone and that the future holds a place for them, no matter what. With their voices united, singing "Let’s sing and sing, let’s grow together," these children aren’t just singing—they’re declaring their belief in themselves, in each other, and in the power of unity. They’re reminded every day at Life School that they have the power to make a difference, that their dreams are worthy, and that together, they are unstoppable. Life School isn’t just a place to learn; it’s a celebration of life, hope, and endless possibility. It’s where dreams take flight, where hearts find strength, and where every child discovers they’re already standing in the light of a brighter future.
</p>

<div class="detailimage-container">
  <img src="${life5}" alt="Blog Detail" class="life-image" />
  <img src="${life6}" alt="Blog Detail" class="life-image" />
</div>

    `,
  };

  return (
    <Box sx={{ backgroundColor: '#f8f8f8' }}>
      {/* Hero Section */}
      <HeroSection>
        <HeroContent>
          <Typography variant="h5" sx={{ fontWeight: 600, fontSize: { xs: '1.5rem', md: '2.5rem' }, marginBottom: 2 }}>
           Unique LifeSchool Programme
          </Typography>
         
        </HeroContent>
      </HeroSection>
      



        {/* Blog Content Section */}
        <Container>
        <Grid container spacing={3} sx={{ my: 4 }}>
          <Grid item xs={12} md={12} sx={{ mb: 4 }}  >
            <Box sx={{ backgroundColor: 'white', borderRadius: 2, boxShadow: '0 4px 20px rgba(0,0,0,0.1)', p: 4 }}>
              <Typography variant="caption" sx={{ color: '#888', mb: 1 }}>
                {blog.date}
              </Typography>
              <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 , fontSize: { xs: '1.5rem', md: '2rem' },}}>
                {blog.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#555', mb: 2 }}>
                by {blog.author}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                  <VisibilityOutlined sx={{ fontSize: 20, color: '#d40032 ' }} />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>{blog.views} views</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ChatBubbleOutline sx={{ fontSize: 20, color: '#d40032 ' }} />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>{blog.comments} comments</Typography>
                </Box>
              </Box>
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            </Box>
          </Grid>

          {/* Sidebar Section */}
          <Grid container spacing={2} style={{ marginLeft: 4 }}>

          <Grid item xs={12} lg={4}>
  <SidebarSection>
    <SectionTitle>Recent Projects</SectionTitle>
    <SectionContent>
      <a href="/lifeschool/1" style={{ textDecoration: 'none', color: ' #d40032' }}>Unique LifeSchool Programme</a>
    </SectionContent>
    <SectionContent>
      <a href="/gudalur/1" style={{ textDecoration: 'none', color: ' #d40032' }}>From Floor to Bench: Gudalur</a>
    </SectionContent>
    <SectionContent>
      <a href="/tailoring/1" style={{ textDecoration: 'none', color: ' #d40032' }}>Tailoring Center:Stitching Future</a>
    </SectionContent>
  </SidebarSection>
</Grid>
<Grid item xs={12} lg={4}>
<SidebarSection>
    <SectionTitle>Get Involved</SectionTitle>
    <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
      Wany to make a difference? Check out our programs and see how you can help.
    </Typography>
    <DonateButton fullWidth      >Join Us</DonateButton>
  </SidebarSection>
</Grid>

<Grid item xs={12} lg={4}>
  <SidebarSection>
    <SectionTitle>Support Our Cause</SectionTitle>
    <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
      Your donations help us continue our mission to improve education for underprivileged children.
    </Typography>
    <DonateButton fullWidth     onClick={scrollToDonate} >Donate</DonateButton>
  </SidebarSection>
</Grid>
</Grid>
        </Grid>
      </Container>

      <Footer />
    </Box>
  );
};

export default Lifeschooldetail;
