import { api } from "./api";


// Auth
export const register = (data) => {
    return api.post("/user/register", data);
};

export const verify = (id, data) => {
    return api.post(`/user/verify/${id}`, data);
};

export const login = (data) => {
    return api.post("/user/login", data);
};

export const forgot = (data) => {
    return api.post("/user/forgetpassword", data);
};

export const reset = (data) => {
    return api.put("/user/resetpassword", data);
};

export const logout = () => {
    return api.get("/user/logout");
};

export const getCurrentUser = () => {
    return api.get("/user/getMyProfile");
};

export const updateProfile = (data) => {
    return api.put("/user/updateProfile", data);
};






export const postCreateOrder = (data) => {
    return api.post("/payment/create-order", data);
};

export const postVerifyPayment = (data) => {
    return api.post("/payment/verify-payment", data);
};

export const sendInvoice = (data) => {
    return api.post("/payment/sendInvoice", data);
};
//blog
export const postBlog = (data) => {
    return api.post("/blog/create", data);
};

export const getAllBlogs = () => {
    return api.get("/blog/getall");
};

export const deleteBlog = (id) => {
    return api.delete(`/blog/delete/${id}`);
};

export const updateBlog = (id, data) => {
    return api.put(`/blog/update/${id}`, data);
};


//event


export const postEvent = (data) => {
    return api.post("/event/create", data);
};

export const getAllEvents = () => {
    return api.get("/event/getall");
};

export const deleteEvent = (id) => {
    return api.delete(`/event/delete/${id}`);
};

export const updateEvent = (id, data) => {
    return api.put(`/event/update/${id}`, data);
};

//gallery
export const postGallery = (data) => {
    return api.post("/gallery/create", data);
};

export const getAllGallery = () => {
    return api.get("/gallery/getall");
};

export const deleteGallery = (id) => {
    return api.delete(`/gallery/delete/${id}`);
};

export const updateGallery = (id, data) => {
    return api.put(`/gallery/update/${id}`, data);
};
