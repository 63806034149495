import React, { useEffect,useCallback } from 'react';
import { Box, Container, Typography, Grid, IconButton, Button, useTheme, useMediaQuery } from '@mui/material';
import { ArrowBack, VisibilityOutlined, ChatBubbleOutline } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom'; 
import heroImage from './images/background/detailabout.png'; 
import blogImage from './images/about/life3.jpeg';
import Footer from './Footer';
import book from './images/about/img1.jpg';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));


const SidebarSection = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  boxShadow: '0 6px 24px rgba(0,0,0,0.1)',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)( {
  fontSize: '1.7rem',
  fontWeight: 600,
  marginBottom: '1rem',
});
const DonateButton = styled(Button)( {
  backgroundColor: ' #d40032',
  color: '#fff',
  fontWeight: 'bold',
  borderRadius: '24px',
  padding: '10px 20px',
  marginTop: '16px',
  '&:hover': {
    backgroundColor: '#b30029',
  },
});
const SectionContent = styled(Typography)( {
  fontSize: '1rem',
  color: '#555',
  marginBottom: '1rem',
});
const Lifeschooldetail = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const scrollToDonate = useCallback(() => {
    navigate('/donateUs');
    setTimeout(() => {
        const donateSection = document.getElementById('donate-us-section');
        if (donateSection) {
            donateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } 
    }, 100);
}, [navigate]);
  
  const blog = {
    title: 'Educational Supply Drive and Distribution: Empowering Futures with Ray of Love Development Foundation',

    date: 'May 4, 2024',
    author: 'Shajan John Edakkad',
    views: 123,
    comments: 45,
    content: `
     
        <p style="text-align: justify; line-height: 1.5;">Ray of Love Development Foundation recently hosted the "Books for Bright Futures" Educational Supply Drive and Distribution event, a heartwarming initiative aimed at ensuring every child has the essential resources to succeed in school. This event was born out of the belief that education is the foundation for a thriving community and that every child, regardless of their circumstances, deserves a chance to succeed.
Over the past few months, we gathered donations of school supplies from generous individuals and local businesses. From notebooks and pencils to uniforms and backpacks, the response was overwhelming, reflecting the community's commitment to supporting the next generation of learners. These contributions allowed us to provide much-needed educational supplies to children who might otherwise go without.

</p>
      <img src="${book}" alt="Blog Detail" style="width: 100%; margin: 20px 0;" />


     
  <p style="text-align: justify; line-height: 1.5;"> On Distribution Day, the venue was filled with excitement as children and their families arrived to collect their supplies. Volunteers had organized the materials by grade level, ensuring that each child received age-appropriate items. The joy was palpable as children tried on new uniforms, selected backpacks, and received fresh notebooks to begin their school year. Alongside the distribution, we also hosted interactive sessions, educating both parents and children on the importance of education, the responsible use of school materials, and how to care for their supplies to make them last.

</p>
 
  <p style="text-align: justify; line-height: 1.5;">This event was made possible by the collective effort of our volunteers, local businesses, and community members, who all came together to support the children and their education. It was a powerful reminder that when we work together, we can make a significant difference in the lives of others. Beyond just providing school supplies, the event gave children the confidence and motivation to start their school year strong, knowing they had the tools they needed to succeed.
Ray of Love Development Foundation remains dedicated to creating opportunities for children to thrive, and this event is just one example of our ongoing commitment to building a brighter future. With the support of the community, we look forward to continuing our work to ensure that every child, regardless of their background, has access to the resources and education they need to reach their full potential.

</p>




 
    `,
  };

  return (
    <Box sx={{ backgroundColor: '#f8f8f8' }}>
      {/* Hero Section */}
      <HeroSection>
        <HeroContent>
          <Typography variant="h5" sx={{ fontWeight: 600, fontSize: { xs: '1.5rem', md: '2.5rem' }, marginBottom: 2 }}>
          Books for Bright Futures
          </Typography>
         
        </HeroContent>
      </HeroSection>
      



        {/* Blog Content Section */}
        <Container>
        <Grid container spacing={3} sx={{ my: 4 }}>
          <Grid item xs={12} md={12} sx={{ mb: 4 }}  >
            <Box sx={{ backgroundColor: 'white', borderRadius: 2, boxShadow: '0 4px 20px rgba(0,0,0,0.1)', p: 4 }}>
              <Typography variant="caption" sx={{ color: '#888', mb: 1 }}>
                {blog.date}
              </Typography>
              <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 , fontSize: { xs: '1.5rem', md: '2rem' },}}>
                {blog.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#555', mb: 2 }}>
                by {blog.author}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                  <VisibilityOutlined sx={{ fontSize: 20, color: '#d40032 ' }} />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>{blog.views} views</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ChatBubbleOutline sx={{ fontSize: 20, color: '#d40032 ' }} />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>{blog.comments} comments</Typography>
                </Box>
              </Box>
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            </Box>
          </Grid>

          {/* Sidebar Section */}
          <Grid container spacing={2} style={{ marginLeft: 4 }}>

          <Grid item xs={12} lg={4}>
  <SidebarSection>
    <SectionTitle>Recent Projects</SectionTitle>
    <SectionContent>
      <a href="/lifeschool/1" style={{ textDecoration: 'none', color: ' #d40032' }}>Unique LifeSchool Programme</a>
    </SectionContent>
    <SectionContent>
      <a href="/gudalur/1" style={{ textDecoration: 'none', color: ' #d40032' }}>From Floor to Bench: Gudalur</a>
    </SectionContent>
    <SectionContent>
      <a href="/tailoring/1" style={{ textDecoration: 'none', color: ' #d40032' }}>Tailoring Center:Stitching Future</a>
    </SectionContent>
  </SidebarSection>
</Grid>

<Grid item xs={12} lg={4}>
<SidebarSection>
    <SectionTitle>Get Involved</SectionTitle>
    <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
      Wany to make a difference? Check out our programs and see how you can help.
    </Typography>
    <DonateButton fullWidth      >Join Us</DonateButton>
  </SidebarSection>
</Grid>

<Grid item xs={12} lg={4}>
  <SidebarSection>
    <SectionTitle>Support Our Cause</SectionTitle>
    <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
      Your donations help us continue our mission to improve education for underprivileged children.
    </Typography>
    <DonateButton fullWidth     onClick={scrollToDonate} >Donate</DonateButton>
  </SidebarSection>
</Grid>
</Grid>
        </Grid>
      </Container>

      <Footer />
    </Box>
  );
};

export default Lifeschooldetail;
