import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, IconButton, useTheme, useMediaQuery, Grid, Pagination, Button } from '@mui/material';
import {  ChatBubbleOutline, VisibilityOutlined, PlayArrowOutlined } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom'; 
import heroImage from './images/background/detailabout.png';
import Footer from './Footer';
import { getAllBlogs } from '../Services/AdminServices';


const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '45vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
   
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  padding: theme.spacing(4),
  maxWidth: '800px',
}));

const HeroTitle = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: 600,
  marginBottom: '1rem',
  lineHeight: 1.2,
 
  [theme.breakpoints.down('sm')]: {
    fontSize: '3rem',
  },
}));

const HeroSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  marginBottom: '2rem',
 
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: '#302c51',
  textAlign: 'center',
  marginBottom: theme.spacing(6),
  fontFamily: "'Montserrat', sans-serif",
}));

const BlogCard = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  backgroundColor: 'white',
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease-in-out',
  height: '100%',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 6px 25px rgba(0,0,0,0.15)',
  },
}));

const BlogPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentPage, setCurrentPage] = useState(1);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const postsPerPage = 3;
  const navigate = useNavigate();

  useEffect(() => {
    fetchBlogs();

   
      window.scrollTo(0, 0); 
   
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await getAllBlogs();
      if (Array.isArray(response.data.blogs)) {
        setBlogs(response.data.blogs);  
      } else {
        console.error('Unexpected data format:', response.data);
        setBlogs([]);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching blogs:', error);
      setBlogs([]);
      setLoading(false);
    }
  };
  

  const totalPosts = blogs.length;
  const totalPages = Math.ceil(totalPosts / postsPerPage);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogs.slice(indexOfFirstPost, indexOfLastPost);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleBlogClick = (id) => {
    navigate(`/BlogDetail/${id}`);
  };

  return (
    <Box sx={{ backgroundColor: '#f8f8f8' }}>
      <HeroSection>
        <HeroContent>
          <HeroTitle variant="h1">Our Impact Blogs</HeroTitle>
          <HeroSubtitle variant="h5">Explore the transformative power of compassion and community</HeroSubtitle>
        </HeroContent>
      </HeroSection>
      
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <SectionTitle variant="h2">Stories of Impact</SectionTitle>
        {loading ? (
          <Typography>Loading blogs...</Typography>
        ) : (
          <Grid container spacing={4}>
            {currentPosts.map((post) => (
              <Grid item xs={12} sm={6} md={4} key={post._id}>
                <BlogCard onClick={() => handleBlogClick(post._id)}>
                  <Box sx={{ position: 'relative' }}>
                    <img 
                     src={post.imagePath.url} 
                     alt={post.title} 
                     style={{ width: '100%', height: '250px', objectFit: 'cover' ,display: 'block'}} 
                    />
                    <Box sx={{ position: 'absolute', top: 16, left: 16, display: 'flex', gap: 1 }}>
                      <Typography
                        variant="caption"
                        sx={{
                          bgcolor: '#d40032 ',
                          color: 'white',
                          px: 1.5,
                          py: 0.5,
                          borderRadius: 1,
                          fontSize: '0.75rem',
                          fontWeight: 600,
                          fontFamily: "'Open Sans', sans-serif",
                        }}
                      >
                        {post.category}
                      </Typography>
                      {post.hasVideo && (
                        <Typography
                          variant="caption"
                          sx={{
                            bgcolor: 'rgba(0,0,0,0.6)',
                            color: 'white',
                            px: 1.5,
                            py: 0.5,
                            borderRadius: 1,
                            fontSize: '0.75rem',
                            fontWeight: 600,
                            fontFamily: "'Open Sans', sans-serif",
                          }}
                        >
                          Video
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 16, right: 16, display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'rgba(0,0,0,0.6)', px: 1, py: 0.5, borderRadius: 1 }}>
                        <VisibilityOutlined sx={{ fontSize: 14, color: 'white' }} />
                        <Typography variant="caption" sx={{ color: 'white', ml: 0.5, fontSize: '0.75rem', fontFamily: "'Open Sans', sans-serif" }}>{post.views}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'rgba(0,0,0,0.6)', px: 1, py: 0.5, borderRadius: 1 }}>
                        <ChatBubbleOutline sx={{ fontSize: 14, color: 'white' }} />
                        <Typography variant="caption" sx={{ color: 'white', ml: 0.5, fontSize: '0.75rem', fontFamily: "'Open Sans', sans-serif" }}>{post.comments}</Typography>
                      </Box>
                    </Box>
                    {post.hasVideo && (
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          bgcolor: 'rgba(255,255,255,0.2)',
                          '&:hover': { bgcolor: '#d40032 ' },
                        }}
                      >
                        <PlayArrowOutlined sx={{ fontSize: 40, color: 'white' }} />
                      </IconButton>
                    )}
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Typography variant="subtitle2" sx={{ color: '#777', mb: 1, fontFamily: "'Open Sans', sans-serif" }}>{new Date(post.date).toLocaleDateString()}</Typography>
                    <Typography variant="h6" sx={{ fontWeight: 600, mb: 1, color: '#302c51', fontFamily: "'Montserrat', sans-serif" }}>
                      {post.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#555', mb: 2, fontFamily: "'Open Sans', sans-serif" }}>
                      By {post.author}
                    </Typography>
                    <Button 
                      variant="outlined" 
                      size="small"
                      sx={{
                        color: '#d40032 ',
                        borderColor: '#d40032 ',
                        '&:hover': {
                          backgroundColor: '#d40032 ',
                          color: 'white',
                        },
                      }}
                      onClick={() => handleBlogClick(post._id)} 
                    >
                      Read More
                    </Button>
                  </Box>
                </BlogCard>
              </Grid>
            ))}
          </Grid>
        )}

{blogs.length > postsPerPage && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                '& .MuiPaginationItem-root': {
                  fontSize: '1rem',
                  fontFamily: "'Open Sans', sans-serif",
                  color: '#302c51',
                },
                '& .Mui-selected': {
                  backgroundColor: '#d40032 ',
                  color: 'white',
                },
              }}
            />
          </Box>
        )}
      </Container>

      <Box sx={{ bgcolor: 'white', py: 10 }}>
        <Container maxWidth="lg">
          <SectionTitle variant="h2">Stay Informed</SectionTitle>
          <Typography variant="body1" sx={{ textAlign: 'center', mb: 6, fontFamily: "'Open Sans', sans-serif", fontSize: '1.1rem' }}>
            Get the latest updates on our programs, partnerships, and community initiatives.
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: 'center', p: 3, bgcolor: '#f8f8f8', borderRadius: 2, height: '100%' }}>
                <Typography variant="h5" sx={{ mb: 2, fontFamily: "'Montserrat', sans-serif", fontWeight: 600 }}>Latest News</Typography>
                <Typography variant="body2" sx={{ fontFamily: "'Open Sans', sans-serif" }}>Stay up-to-date with our most recent developments and announcements.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: 'center', p: 3, bgcolor: '#f8f8f8', borderRadius: 2, height: '100%' }}>
                <Typography variant="h5" sx={{ mb: 2, fontFamily: "'Montserrat', sans-serif", fontWeight: 600 }}>Program Updates</Typography>
                <Typography variant="body2" sx={{ fontFamily: "'Open Sans', sans-serif" }}>Learn about the progress and impact of our ongoing programs.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: 'center', p: 3, bgcolor: '#f8f8f8', borderRadius: 2, height: '100%' }}>
                <Typography variant="h5" sx={{ mb: 2, fontFamily: "'Montserrat', sans-serif", fontWeight: 600 }}>Community Highlights</Typography>
                <Typography variant="body2" sx={{ fontFamily: "'Open Sans', sans-serif" }}>Discover inspiring stories from our community members and volunteers.</Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 10 }}>
        <SectionTitle variant="h2">Share Your Story</SectionTitle>
        <Typography variant="body1" sx={{ textAlign: 'center', mb: 4, fontFamily: "'Open Sans', sans-serif", fontSize: '1.1rem' }}>
          Your experience can inspire others and showcase the impact of our collective efforts.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button 
            variant="contained" 
            size="large" 
            sx={{ 
              backgroundColor: '#d40032 ', 
              '&:hover': { backgroundColor: '#b8002d' },
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: 600,
              px: 4,
              py: 1.5,
            }}
          >
            Submit Your Story
          </Button>
        </Box>
      </Container>

      <Footer />
    </Box>
  );
};

export default BlogPage;