import React from 'react';
import { Link } from 'react-router-dom';
import project1 from './images/about/life2.jpeg';
 import project2 from './images/about/gudalur.png';
  import project4 from './images/about/goat2.jpeg';  
const Project = () => {
  const projects = [
    { 
      title: "Life School Programme", 
      image: project1,
      route: "/lifeschool/1"
    },
    { 
      title: "From Floor to Bench:Gudalur", 
      image: project2,
      route: "/gudalur/1"
    },
    { 
      title: "Goat Rearing for Women", 
      image: project4,
      route: "/goat/1"
    },
  ];

  return (
    <section className="projects-section">
      <div className="auto-container">
        <div className="sec-title text-center">
          <h1>Our Completed Projects</h1>
          <p className="text">
            Making a difference in the lives of many through targeted initiatives focused on education, healthcare, and community development.
          </p>
        </div>
        <div className="row">
          {projects.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </div>
    </section>
  );
};

const ProjectCard = ({ project }) => {
  return (
    <div className="project-block-one">
      <div className="inner-box">
        <div className="image">
          <img src={project.image} alt={project.title} />
          <div className="gradient-overlay"></div>
        </div>
        <h3>
          <Link to={project.route}>
            {project.title}
          </Link>
        </h3>
      </div>
    </div>
  );
};

export default Project;