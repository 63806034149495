import React, { useEffect,useCallback  } from 'react';
import { Box, Container, Typography, Grid, Button, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import heroImage from './images/background/detailabout.png'; 
import eventDetailImage from './images/about/news3.jpeg'; 
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));



const EventBlock = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  boxShadow: '0 6px 24px rgba(0,0,0,0.1)',
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const EventTitle = styled(Typography)( {
  fontSize: '2.2rem',
  fontWeight: 600,
});

const EventImage = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  borderRadius: '8px',
  marginBottom: '16px',
});

const EventDetails = styled(Typography)( {
  fontSize: '1rem',
  color: '#333',
  marginBottom: '1.2rem',
  marginTop: '8px',
});

const EventDescription = styled(Typography)( {
  fontSize: '1rem',
  color: '#444',
  lineHeight: 1.6,
  marginBottom: '2rem',
});

const SidebarSection = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  boxShadow: '0 6px 24px rgba(0,0,0,0.1)',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)( {
  fontSize: '1.7rem',
  fontWeight: 600,
  marginBottom: '1rem',
});

const SectionContent = styled(Typography)( {
  fontSize: '1rem',
  color: '#555',
  marginBottom: '1rem',
});

const DonateSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: 8,
  boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
  padding: theme.spacing(3),
  marginTop: theme.spacing(2),
  textAlign: 'center',
}));

const DonateButton = styled(Button)( {
  backgroundColor: ' #d40032',
  color: '#fff',
  fontWeight: 'bold',
  borderRadius: '24px',
  padding: '10px 20px',
  marginTop: '16px',
  '&:hover': {
    backgroundColor: '#b30029',
  },
});

const EventDetail = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToDonate = useCallback(() => {
    navigate('/donateUs');
    setTimeout(() => {
        const donateSection = document.getElementById('donate-us-section');
        if (donateSection) {
            donateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } 
    }, 100);
}, [navigate]);
  const event = {
    title: 'Empowering Leaders for a Brighter Tomorrow',
    date: '05 December 2024, 09.00am',
    location: 'Kottayam',
    description: `
      Leadership training programme was a memorable event filled with heartwarming moments. Attendees enjoyed a series of inspiring speeches, live performances, and a silent auction, all aimed at raising funds for educational programs and resources for underprivileged children.
    `,
    highlights: `
      - Inspirational speeches by advocates for children's education.
      - Live performances by local artists.
      - A silent auction with generous contributions from local businesses.
    `,
    notableGuests: `
      - Dr. Emily Johnson, Education Advocate.
      - Mr. Michael Lee, Founder of EduCharity.
    `,
    followUpActions: `
      - Send thank-you letters to all donors and participants.
      - Review the success of the fundraising activities and report to the community.
      - Plan future events to continue supporting educational initiatives.
    `,
  };

  return (
    <Box sx={{ backgroundColor: '#f8f8f8' }}>
      <HeroSection>
        <HeroContent>
          <Typography variant="h5" sx={{ fontWeight: 600, fontSize: { xs: '1.5rem', md: '2.5rem' }, marginBottom: 2 }}>
            {event.title}
          </Typography>
          <Typography variant="h7" sx={{ fontWeight: 400, fontSize: { xs: '1rem', md: '2rem' }, marginBottom: 4 }}>
            Leadership Training Programme
          </Typography>
        </HeroContent>
      </HeroSection>

      <Container>
        <Grid container spacing={3} sx={{ my: 4 }}>
          <Grid item xs={12} md={12}>
            <EventBlock>
              <EventTitle
                variant="h9"
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: '1.5rem', md: '2rem' },
                }}
              >
                {event.title}
              </EventTitle>
              <EventDetails>
                <strong>Date:</strong> {event.date}
                <br />
                <strong>Location:</strong> {event.location}
              </EventDetails>
              <EventImage src={eventDetailImage} alt="Event" />
              <EventDescription>{event.description}</EventDescription>
            </EventBlock>
          </Grid>

          {/* New Grid layout for the Sidebar sections */}
          <Grid container spacing={2} style={{ marginLeft: 4 }}>

            <Grid item xs={12} lg={4}>
              <SidebarSection>
                <SectionTitle>Upcoming Events</SectionTitle>
                <SectionContent>
                  <a href="#" style={{ textDecoration: 'none', color: ' #d40032' }}>How Charity Work Can Change Lives</a>
                </SectionContent>
                <SectionContent>
                  <a href="#" style={{ textDecoration: 'none', color: ' #d40032' }}>Volunteering Tips for Beginners</a>
                </SectionContent>
                <SectionContent>
                  <a href="#" style={{ textDecoration: 'none', color: ' #d40032' }}>The Impact of Donations</a>
                </SectionContent>
              </SidebarSection>
            </Grid>

            <Grid item xs={12} lg={4}>
            <SidebarSection>
                <SectionTitle>Get Involved</SectionTitle>
                <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
                  Wany to make a difference? Check out our programs and see how you can help.
                </Typography>
                <DonateButton fullWidth      >Join Us</DonateButton>
              </SidebarSection>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SidebarSection>
                <SectionTitle>Support Our Cause</SectionTitle>
                <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
                  Your donations help us continue our mission to improve education for underprivileged children.
                </Typography>
                <DonateButton fullWidth     onClick={scrollToDonate} >Donate</DonateButton>
              </SidebarSection>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </Box>
  );
};

export default EventDetail;
