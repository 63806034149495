import React ,{useEffect} from 'react';
import { Grid, Typography, Box, Container, Card, CardContent, CardMedia, Button, Divider } from '@mui/material';

import heroImage from './images/background/detailabout.png';
import { styled } from '@mui/system';
import mission from './images/about/mission.jpg';
import vision from './images/about/vision.jpg';
import Footer from './Footer';
import team1 from './images/about/saji.jpeg';
import team2 from './images/about/shajan.jpeg';
import team3 from './images/about/james.jpeg';
import team4 from './images/about/lovegy.jpeg';
const HeroSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: `rgba(0.1, 0.2, 0.1, 0.5)`, // Shadow effect with semi-transparent black
    
    height: '45vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    textAlign: 'center',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      
    },
  }));
  

  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#d40032 ',
    color: 'white',
    borderRadius: '25px',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: '#c0002f',
    },
  }));
  const HeroContent = styled(Box)(({ theme }) => ({
    zIndex: 1,
    maxWidth: '800px',
    padding: theme.spacing(4),
  }));
const DetailAbout = () => {


    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);



    return (
        <>
        <Box sx={{ backgroundColor: '#e0e0e0', color: '#1b1b1b' }}>
        <HeroSection>
        <HeroContent>
          <Typography variant="h1" sx={{ fontWeight: 600, fontSize: { xs: '2rem', md: '2.5rem' }, marginBottom: 2 }}>
          About Us
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 300, marginBottom: 4 }}>
          Empowering Families, Building Communities
          </Typography>
          {/* <StyledButton variant="contained" size="large"   >  
          OUR STORY
          </StyledButton> */}
        </HeroContent>
      </HeroSection>

            <Container maxWidth="lg" sx={{ py: 12 }}>
                <Grid container spacing={8} alignItems="center">
                   
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" sx={{  fontWeight: 600, color: '#302c51', mb:2 ,mt:-6}}>
                            Our Mission
                        </Typography>
                        <Typography variant="body1" sx={{  fontSize: '18px', mb: 3, color: '#1b1b1b', lineHeight: 1.7, textAlign: 'justify' }}>
                        At the Ray of Love Development Foundation, our mission is to empower families and communities by providing the necessary tools, resources, and support for their holistic development. We believe that a community can only grow and prosper when its families are strong, resilient, and equipped to handle life’s challenges. Our approach focuses on fostering stability, emotional well-being, and social cohesion within families. Through initiatives that span education, health, economic empowerment, leadership development, and social welfare, we are committed to creating environments where families can thrive. By nurturing the potential of each family, we aim to build sustainable communities that are self-sufficient, united, and focused on long-term growth and progress.

                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            src={mission}
                            alt="Our Mission"
                            sx={{
                                mt:-8,
                                width: '100%',
                                height: 400,
                                objectFit: 'cover',
                                borderRadius: 4,
                                boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                            }}
                        />
                    </Grid>







                </Grid>

                <Divider sx={{ my:3, borderColor: '#d40032 ', borderWidth: 2 }} />

                <Grid container spacing={8} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" sx={{  fontWeight: 600, color: '#302c51', mb:2 }}>
                             Our Vision
                        </Typography>
                        <Typography variant="body1" sx={{  fontSize: '18px', mb: 3, color: '#1b1b1b', lineHeight: 1.7, textAlign: 'justify' }}>
                        Our vision is to transform the lives of families and communities by guiding them toward sustainable, self-reliant growth. We envision a world where families are not just survivors but thriving, empowered change-makers who contribute to the well-being of their communities. At the heart of our vision is the belief that when families are strong, they can shape the future with values of love, respect, integrity, and kindness. Our work encompasses education, providing safe spaces for children to learn and grow, economic empowerment, ensuring families have the resources to support themselves, and leadership training, preparing the next generation to be leaders in their communities. We aim to create a ripple effect where the positive changes in one family lead to wider transformation in the community, building a future based on unity, compassion, and shared progress.

                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            src={vision}
                            alt="Our Vision"
                            sx={{
                                mt:-8,
                                width: '100%',
                                height: 400,
                                objectFit: 'cover',
                                borderRadius: 4,
                                boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                            }}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ my: 12 }}>
                    <Typography variant="h4" sx={{  fontWeight: 700, color: '#302c51', mb:4,mt:-8, textAlign: 'center' }}>
                        Meet Our Team
                    </Typography>
                    <Grid container spacing={4}>
                        {[ 
                            { name: 'Saji Mathai Kathettu', role: 'Director', image: team1 },
                            { name: 'Shajan John Edakkad', role: 'Director ', image: team2 },
                            { name: 'James Chacko', role: 'Director', image: team3 },
                            { name: 'Lovegy Pappachan', role: 'Field Development Cordinator', image: team4 },
                        ].map((member, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: 4, overflow: 'hidden', boxShadow: '0 10px 30px rgba(0,0,0,0.1)', transition: 'all 0.3s ease-in-out', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 20px 40px rgba(0,0,0,0.2)' } }}>
                                    <CardMedia
                                        component="img"
                                        height="auto"
                                        image={member.image}
                                        alt={member.name}
                                    />
                                    <CardContent sx={{ flexGrow: 1, textAlign: 'center', bgcolor: 'white', p: 3 }}>
                                        <Typography variant="h6" sx={{ fontFamily: "'Playfair Display', serif", fontWeight: 600, color: '#302c51', mb: 1 }}>
                                            {member.name}
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ fontFamily: "'Lato', sans-serif", color: '#d40032 ' }}>
                                            {member.role}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* <Box sx={{ my: 12, p: 8, backgroundColor: '#302c51', borderRadius: 8, color: 'white', boxShadow: '0 20px 40px rgba(0,0,0,0.2)' }}>
                    <Typography variant="h2" sx={{ fontFamily: "'Playfair Display', serif", fontWeight: 700, mb: 6, color: 'white', textAlign: 'center' }}>
                        Our Impact
                    </Typography>
                    <Grid container spacing={6} justifyContent="center">
                        {[ 
                            { icon: <EmojiPeopleIcon sx={{ fontSize: 60 }} />, label: 'Volunteers', count: 5000 },
                            { icon: <PublicIcon sx={{ fontSize: 60 }} />, label: 'Countries', count: 30 },
                            { icon: <CalendarTodayIcon sx={{ fontSize: 60 }} />, label: 'Events', count: 120 },
                            { icon: <PeopleIcon sx={{ fontSize: 60 }} />, label: 'Beneficiaries', count: 100000 },
                        ].map((item, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <Box sx={{ textAlign: 'center', p: 3, backgroundColor: 'rgba(255,255,255,0.1)', borderRadius: 4, transition: 'all 0.3s ease', '&:hover': { transform: 'translateY(-10px)', backgroundColor: 'rgba(255,255,255,0.2)' } }}>
                                    <Box sx={{ color: '#d40032 ', mb: 2 }}>{item.icon}</Box>
                                    <Typography variant="h3" sx={{ fontFamily: "'Lato', sans-serif", fontWeight: 700, mb: 1, color: 'white' }}>
                                        <CountUp end={item.count} duration={2.5} separator="," />
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontFamily: "'Lato', sans-serif", color: 'white', opacity: 0.8 }}>
                                        {item.label}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box> */}

                <Box sx={{ my: 10, p: 5,mt:-8,mb:-8, backgroundColor: 'white', borderRadius: 8, textAlign: 'center', boxShadow: '0 20px 40px rgba(0,0,0,0.1)', position: 'relative', overflow: 'hidden' }}>
                    <Box sx={{ position: 'absolute', top: -100, right: -100, width: 300, height: 300, backgroundColor: '#d40032 ', borderRadius: '50%', opacity: 0.1 }} />
                    <Box sx={{ position: 'absolute', bottom: -50, left: -50, width: 200, height: 200, backgroundColor: '#302c51', borderRadius: '50%', opacity: 0.1 }} />
                    <Typography variant="h4" sx={{  fontWeight: 700, mb: 3, color: '#302c51', position: 'relative', zIndex: 2 }}>
                        Join Our Mission
                    </Typography>
                    <Typography variant="h6" sx={{ fontFamily: "'Lato', sans-serif", fontWeight: 300, mb: 4, color: '#1b1b1b', maxWidth: '800px', mx: 'auto', position: 'relative', zIndex: 2 }}>
                        Be part of our journey to create positive change in communities worldwide.
                    </Typography>
                    <Button 
                        variant="contained" 
                        sx={{ 
                            backgroundColor: '#d40032 ',
                            color: 'white',
                            fontFamily: "'Lato', sans-serif",
                            fontSize: '1.2rem',
                            padding: '15px 50px',
                            borderRadius: '50px',
                            '&:hover': { 
                                backgroundColor: '#ff0040',
                                transform: 'translateY(-3px)',
                                boxShadow: '0 6px 20px rgba(212, 0, 50, 0.4)',
                            },
                            transition: 'all 0.3s ease-in-out',
                            position: 'relative',
                            zIndex: 2,
                        }}
                    >
                        Get Involved
                    </Button>
                </Box>
            </Container>
        </Box>
        <Footer />
        </>
    );
};

export default DetailAbout;