import React from 'react';
import { Box, Grid, Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { LocationOn, AccessTime } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import event1 from './images/about/leader.jpg';
import event2 from './images/about/event2.jpeg';
import event3 from './images/about/event3.jpeg';
import event4 from './images/about/event4.jpeg';

// Styled Components
const EventCard = styled(Box)({
  position: 'relative',
  borderRadius: '12px',
  overflow: 'hidden',
  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  transition: 'all 0.3s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 15px 35px rgba(0, 0, 0, 0.2)',
  },
});

const EventImage = styled('img')({
  width: '100%',
  height: '250px',
  objectFit: 'cover',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const EventContent = styled(Box)({
  padding: '24px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const EventDate = styled(Box)({
  position: 'absolute',
  top: '20px',
  left: '20px',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  padding: '12px 20px',
  borderRadius: '8px',
  textAlign: 'center',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
});

const EventTitle = styled(Typography)({
  fontWeight: '700',
  marginBottom: '12px',
  color: '#1a1a1a',
  fontSize: '1.2rem',
  lineHeight: 1.3,
});

const EventDetails = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  color: '#555',
  fontSize: '0.95rem',
});

const ExploreButton = styled(Button)({
  backgroundColor: '#d40032',
  color: '#fff',
  borderRadius: '30px',
  padding: '12px 30px',
  fontSize: '1rem',
  fontWeight: '600',
  textTransform: 'none',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: '#a8002a',
    transform: 'translateY(-2px)',
    boxShadow: '0 5px 15px rgba(212, 0, 50, 0.3)',
  },
});

const SectionHeading = styled(Typography)({
  color: '#fff',
  fontWeight: '500',
  marginBottom: '24px',
  fontSize: '2rem',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-10px',
    left: '0',
    width: '60px',
    height: '4px',
    backgroundColor: '#d40032',
  },
});

const EventSection = () => {
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate('/eventsPage');
  };

  const handleEventClick = (eventNumber) => {
    navigate(`/event${eventNumber}/1`);
  };

  const events = [
    {
      image: event1,
      title: "Ray of Love Leadership Training: Nurturing Community Leaders",
      date: "05",
      month: "Sep 2024",
      time: "09:00AM - 05:00PM",
      location: "Kottayam",
      eventNumber: 1
    },
    {
      image: event2,
      title: "Educational Supply Drive and Book Distribution",
      date: "10",
      month: "Jul 2024",
      time: "09:00AM - 05:00PM",
      location: "Trivandrum",
      eventNumber: 2
    },
    {
      image: event3,
      title: "Skill Development and Vocational Training for Women",
      date: "15",
      month: "May 2024",
      time: "09:00AM - 05:00PM",
      location: "Kottayam",
      eventNumber: 3
    },
    {
      image: event4,
      title: "Kid Cooking: Discovering Love and Unity",
      date: "20",
      month: "Feb 2024",
      time: "09:00AM - 05:00PM",
      location: "Kottayam",
      eventNumber: 4
    }
  ];

  return (
    <Box sx={{ 
      py: 8, 
      backgroundColor: '#f8f8f8',
      backgroundSize: 'cover', 
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed'
    }}>
      <Container maxWidth="lg">
        <div className="sec-title text-center" style={{ marginTop: '-50px' }}>
          <h1>Our Events</h1>
          <p className="text">
            Hosting Landmark Events that Drive Change, Foster Connections, and Shape the Future of Communities
          </p>
        </div>
        <Grid container spacing={1}>
          {events.map((event, index) => (
            <Grid item xs={12} lg={3} key={index}>
              <EventCard onClick={() => handleEventClick(event.eventNumber)}>
                <EventImage src={event.image} alt={event.title} />
                <EventDate>
                  <Typography variant="h5" sx={{ color: '#d40032', fontWeight: '800' }}>
                    {event.date}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#333', fontWeight: '600' }}>
                    {event.month}
                  </Typography>
                </EventDate>
                <EventContent>
                  <EventTitle variant="h6">
                    {event.title}
                  </EventTitle>
                  <EventDetails>
                    <AccessTime sx={{ fontSize: '1rem', marginRight: '8px', color: '#d40032' }} />
                    <Typography variant="body2">{event.time}</Typography>
                  </EventDetails>
                  <EventDetails>
                    <LocationOn sx={{ fontSize: '1rem', marginRight: '8px', color: '#d40032' }} />
                    <Typography variant="body2">{event.location}</Typography>
                  </EventDetails>
                </EventContent>
              </EventCard>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: 'center', mt: 6 }}>
          <ExploreButton variant="contained" onClick={handleViewMoreClick}>
            Explore More Events
          </ExploreButton>
        </Box>
      </Container>
    </Box>
  );
};

export default EventSection;