import React, { useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import slide1 from './images/background/hero.jpeg';
import xshome from './images/about/xshome.jpg';
import '../index.css';

const Slide = () => {
  const navigate = useNavigate();

  const scrollToDonate = useCallback(() => {
    navigate('/donateUs');
    setTimeout(() => {
      const donateSection = document.getElementById('donate-us-section');
      if (donateSection) {
        donateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  }, [navigate]);

  const goToDetails = useCallback(() => {
    navigate('/detailAbout');
  }, [navigate]);

  return (
    <Box className="slider-container">
      {/* Mobile View (xs screens) */}
      <Box 
        sx={{ 
          display: { xs: 'block', lg: 'none' },
          width: '100%',
          height: '100%',
          backgroundImage: `url(${xshome})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />

      {/* Desktop View (lg screens) */}
      <Box 
        sx={{ 
          display: { xs: 'none', lg: 'block' },
          width: '100%',
          height: '100%'
        }}
      >
        <Box 
          className="slide-background" 
          style={{ backgroundImage: `url(${slide1})` }}
        >
          <Box
            className="slide-overlay"
            sx={{
              marginLeft: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '8px',
              textAlign: 'center',
            }}
          >
            <Typography
              className="slide-title"
              sx={{
                fontSize: '3.5rem',
                fontWeight: 'bold',
                color: 'rgb(105, 18, 18)',
              }}
            >
              JOIN HANDS <br />
              FOR A BRIGHTER TOMORROW
            </Typography>

            <Box className="slide-buttons">
              <Button
                className="slide-button"
                style={{ backgroundColor: '#ecb23b' }}
                onClick={goToDetails}
              >
                More Details
              </Button>
              <Button
                className="slide-button"
                style={{ backgroundColor: '#ecb23b' }}
                onClick={scrollToDonate}
              >
                Donate Now
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Slide;