import React from 'react';

function ShippingPolicy() {
    return (
        <div style={{ margin: 20 }}>
            <h2>Shipping Policy</h2>
            <p className='footer-text'>
                This Shipping Policy outlines our practices regarding the shipment of products ordered through our service. By using our service, you agree to the terms outlined in this policy.
            </p>

            <h3>General Shipping Information</h3>
            <p className='footer-text'>
                We aim to deliver your orders as quickly as possible. Please note that processing times may vary based on product availability.
            </p>

            <h3>Shipping Methods and Costs</h3>
            <ul>
                <li>
                    <p className='footer-text'><strong>Standard Shipping:</strong> $5.99, delivery in 5-7 business days.</p>
                </li>
                <li>
                    <p className='footer-text'><strong>Expedited Shipping:</strong> $12.99, delivery in 2-3 business days.</p>
                </li>
                <li>
                    <p className='footer-text'><strong>Free Shipping:</strong> Available for orders over $50.</p>
                </li>
            </ul>

            <h3>International Shipping</h3>
            <p className='footer-text'>
                We offer international shipping to select countries. Additional fees may apply, including customs duties and taxes.
            </p>

            <h3>Order Tracking</h3>
            <p className='footer-text'>
                Once your order has been shipped, you will receive an email with tracking information so you can monitor its status.
            </p>

            <h3>Delivery Issues</h3>
            <p className='footer-text'>
                If you encounter any issues with your delivery, please contact us at <a href="mailto:rayoflovedf@gmail.com">rayoflovedf@gmail.com</a>.
            </p>

            <h3>Changes to Shipping Policy</h3>
            <p className='footer-text'>
                We may update our Shipping Policy from time to time. We will notify you of any changes by updating this page.
            </p>

            <h3>Contact Us</h3>
            <p className='footer-text'>
                If you have any questions about our Shipping Policy, you can contact us:
            </p>
            <ul>
                <li>By email: <a href="mailto:rayoflovedf@gmail.com">rayoflovedf@gmail.com</a></li>
            </ul>
        </div>
    );
}

export default ShippingPolicy;
