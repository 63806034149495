import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const EditItemPage = ({ saveEditedItem }) => {
  const location = useLocation(); // Use useLocation to retrieve the state
  const currentItem = location.state?.item; // Retrieve the item from state
  const [newTitle, setNewTitle] = useState(currentItem?.title || '');
  const navigate = useNavigate();

  const handleSave = () => {
    if (currentItem) {
      saveEditedItem({ oldTitle: currentItem.title, newTitle }); // Save the updated item
    }
    navigate('/'); 
  };

  if (!currentItem) {
    return <Typography>No item selected for editing</Typography>;
  }

  return (
    <Box padding={2}>
      <Typography variant="h4">Edit Item</Typography>
      <TextField
        label="Title"
        value={newTitle}
        onChange={(e) => setNewTitle(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Box marginTop={2}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default EditItemPage;
