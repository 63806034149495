import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, TextField, Button, InputAdornment, IconButton, Snackbar, Alert } from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import { postBlog, updateBlog } from '../Services/AdminServices';

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: '#302c51',
  textAlign: 'center',
  marginBottom: theme.spacing(6),
  fontFamily: "'Montserrat', sans-serif",
}));

const FormField = styled(TextField)(({ theme, error }) => ({
  marginBottom: theme.spacing(3),
  ...(error && {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
    },
  }),
}));

const ImagePreview = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: 200,
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const BlogPostAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const existingBlog = location.state?.blog;

  const [title, setTitle] = useState(existingBlog?.title || '');
  const [subheading, setSubheading] = useState(existingBlog?.subheading || '');
  const [category, setCategory] = useState(existingBlog?.category || '');
  const [author, setAuthor] = useState(existingBlog?.author || '');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(existingBlog?.imagePath?.url || '');
  const [content, setContent] = useState(existingBlog?.content || '');
  const [isLoading, setIsLoading] = useState(false);
  
  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const [errors, setErrors] = useState({
    title: false,
    subheading: false,
    category: false,
    author: false,
    image: false,
    content: false,
  });

  useEffect(() => {
    if (existingBlog) {
      setTitle(existingBlog.title);
      setSubheading(existingBlog.subheading);
      setCategory(existingBlog.category);
      setAuthor(existingBlog.author);
      setImage(existingBlog.imagePath ? existingBlog.imagePath.file : null); 
      setImagePreview(existingBlog.imagePath?.url);
      setContent(existingBlog.content);
    }
  }, [existingBlog]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
      setErrors(prev => ({ ...prev, image: false })); // Reset error if a file is selected
    }
  };

  const validateFields = () => {
    const newErrors = {
      title: !title,
      subheading: !subheading,
      category: !category,
      author: !author,
      image: existingBlog ? false : !image, // No error if updating and an image exists
      content: !content,
    };
    setErrors(newErrors);
    return Object.values(newErrors).every(error => !error);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!validateFields()) {
      setIsLoading(false);
      return; // Stop submission if validation fails
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('subheading', subheading);
    formData.append('category', category);
    formData.append('author', author);
    formData.append('content', content);
    formData.append('image', image);

    try {
      let response;
      if (existingBlog) {
        response = await updateBlog(existingBlog._id, formData);
      } else {
        response = await postBlog(formData);
      }
    
      if (response.status === 200 || response.status === 201) {
        setSnackbar({
          open: true,
          message: existingBlog ? 'Blog updated successfully!' : 'Blog created successfully!',
          severity: 'success',
        });
        // Redirect to homepage after 2 seconds
        setTimeout(() => navigate('/'), 2000);
      } else {
        throw new Error(existingBlog ? 'Failed to update blog' : 'Failed to create blog');
      }
    } catch (error) {
      console.error('Error details:', error.response ? error.response.data : error.message);
      setSnackbar({
        open: true,
        message: `Error occurred while ${existingBlog ? 'updating' : 'creating'} the blog: ${error.message}`,
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ backgroundColor: '#f8f8f8', py: 10 }}>
      <Container maxWidth="lg">
        <Button 
          variant="outlined" 
          sx={{ mb: 4, textTransform: 'none', color: "#302c51", borderColor: "#302c51", '&:hover': { backgroundColor: '#f0f0f0' }}} 
          onClick={handleCancel} 
        >
          Go Back
        </Button>
        <SectionTitle variant="h2">{existingBlog ? 'Edit Blog Post' : 'Post a New Blog'}</SectionTitle>
        <Box component="form" onSubmit={handleSubmit}>
          <FormField
            fullWidth
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={errors.title}
            required
          />
          <FormField
            fullWidth
            label="Subheading"
            variant="outlined"
            value={subheading}
            onChange={(e) => setSubheading(e.target.value)}
            error={errors.subheading}
            required
          />
          <FormField
            fullWidth
            label="Category"
            variant="outlined"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            error={errors.category}
            required
          />
          <FormField
            fullWidth
            label="Author"
            variant="outlined"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            error={errors.author}
            required
          />
          <FormField
            fullWidth
            label="Image"
            variant="outlined"
            value={image ? image.name : ''}
            error={existingBlog ? false : errors.image} // Show red border only if not updating or image is not provided
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton component="label">
                    <AttachFile />
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            readOnly
          />
          {imagePreview && <ImagePreview src={imagePreview} alt="Image preview" />}
          <FormField
            fullWidth
            label="Content"
            variant="outlined"
            multiline
            rows={6}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            error={errors.content}
            required
          />
          <Box sx={{ textAlign: 'center', mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: 600,
                px: 4,
                py: 1.5,
                color: "#302c51",
                borderColor: "#302c51",
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              sx={{
                backgroundColor: '#d40032',
                '&:hover': { backgroundColor: '#b8002d' },
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: 600,
                px: 4,
                py: 1.5,
              }}
            >
              {isLoading ? (existingBlog ? 'Updating...' : 'Posting...') : (existingBlog ? 'Update Blog' : 'Post Blog')}
            </Button>
          </Box>
        </Box>
        
        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'top' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{
              width: '25%',
              backgroundColor: 'white',
              color: '#d40032',
              '& .MuiSvgIcon-root': {
                color: '#d40032',
              }
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default BlogPostAdmin;
