import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Button, Grid, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import heroImage from './images/background/detailabout.png';
import event1 from './images/about/leader.jpg';
import event2 from './images/about/event2.jpeg';
import event3 from './images/about/event3.jpeg';
import event4 from './images/about/event4.jpeg';
import Footer from './Footer';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '45vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(4),
  position: 'relative',
  display: 'inline-block',
  color: '#302c51',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-10px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '3px',
    backgroundColor: '#d40032 ',
  },
}));

const EventCard = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '12px',
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const EventImage = styled('img')({
  width: '100%',
  height: '250px',
  objectFit: 'cover',
});

const EventContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const EventDate = styled(Typography)(({ theme }) => ({
  color: '#d40032 ',
  fontWeight: 600,
  marginBottom: theme.spacing(1),
}));

const EventTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  color: '#302c51',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#d40032 ',
  color: 'white',
  borderRadius: '25px',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#c0002f',
  },
}));

const SectionDescription = styled(Typography)(({ theme }) => ({
  maxWidth: '800px',
  margin: '0 auto',
  marginBottom: theme.spacing(6),
  textAlign: 'center',
  color: '#555',
}));

const EventPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Replace the previous fetching logic with hardcoded events
  const events = [
    {
      image: event1,
      title: 'Ray of Love Leadership Training: Nurturing Tomorrows Community Leaders',
      date: '05',
      month: 'Sep 2024',
      time: '09:00AM - 05:00PM',
      location: 'Kottayam',
      eventNumber: 1
    },
    {
      image: event2,
      title: 'Educational Supply Drive and Book Distribution',
      date: '10',
      month: 'Jul 2024',
      time: '09:00AM - 05:00PM',
      location: 'Trivandrum',
      eventNumber: 2
    },
    {
      image: event3,
      title: 'Skill Development and Vocational Training for Women',
      date: '15',
      month: 'May 2024',
      time: '09:00AM - 05:00PM',
      location: 'Kottayam',
      eventNumber: 3
    },
    {
      image: event4,
      title: 'Kid Cooking: Discovering Love, Sacrifice, and Unity at Life School',
      date: '20',
      month: 'Feb 2024',
      time: '09:00AM - 05:00PM',
      location: 'Kottayam',
      eventNumber: 4
    }
  ];

  const handleViewMoreClick = (eventNumber) => {
    switch (eventNumber) {
      case 1:
        navigate(`/event1/${eventNumber}`);
        break;
      case 2:
        navigate(`/event2/${eventNumber}`);
        break;
      case 3:
        navigate(`/event3/${eventNumber}`);
        break;
      case 4:
        navigate(`/event4/${eventNumber}`);
        break;
      default:
        navigate('/');
    }
  };
  

  const handleButtonClick = () => {
    navigate('/contactPage');
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroSection>
        <HeroContent>
          <Typography variant="h1" sx={{ fontWeight: 600, fontSize: { xs: '2rem', md: '2.5rem' }, marginBottom: 2 }}>
            Discover Our Events
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 300, marginBottom: 4 }}>
            Join us for exciting activities that make a difference.
          </Typography>
        </HeroContent>
      </HeroSection>

     

      <Box sx={{ py: 10, backgroundColor: 'white' ,mt:-8}}>
        <Container>
          <div className="sec-title text-center">
            <h1 style={{ textAlign: 'left' }}>Our Events</h1>
          </div>
          <SectionDescription variant="body1">
            Explore summaries and photos from our past events. Celebrate our successes and see firsthand how our community comes together to create lasting impact through collaboration and dedication.
          </SectionDescription>

          {/* Placeholder for future API loading state */}
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={4}>
              {events.map((event) => (
                <Grid item xs={12} md={6} key={event.eventNumber}>
                  <EventCard>
                    <EventImage src={event.image} alt={event.title} />
                    <EventContent>
                      <EventDate variant="subtitle1">{event.date} {event.month} {event.year}</EventDate>
                      <EventTitle variant="h5">{event.title}</EventTitle>
                      <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
                        {event.location} | {event.time}
                      </Typography>
                      <StyledButton onClick={() => handleViewMoreClick(event.eventNumber)}>
                        View Recap
                      </StyledButton>
                    </EventContent>
                  </EventCard>
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Box>

      <Box sx={{ py: 10, backgroundColor: '#f9f9f9', textAlign: 'center' }}>
        <Container>
          <SectionTitle variant="h4">
            Get Involved
          </SectionTitle>
          <SectionDescription variant="body1">
            Interested in volunteering or attending an upcoming event? Learn how you can contribute your time, skills, or resources to support our mission. Together, we can make a difference in the lives of individuals and families across our community.
          </SectionDescription>
          <StyledButton variant="contained" size="large" onClick={handleButtonClick}>
            Join Our Community
          </StyledButton>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default EventPage;
