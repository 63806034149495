import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import '../index.css';
import cause1 from './images/about/lifeschool.jpeg';
import cause2 from './images/about/stich.jpeg';
import cause6 from './images/about/goat.jpeg';

const DonationProjects = () => {
  const projects = [
    {
      id: 'lifeschool',
      image: cause1,
      title: 'Unique Life School Programme',
      description: 'A nurturing space for children to grow and develop leadership skills for a brighter tomorrow.',
      raised: '25,000',
      goal: '50,000',
      progress: 50,
    },
    {
      id: 'tailoring',
      image: cause2,
      title: 'Tailoring Center: Stitching Future',
      description: 'Equipping women with tailoring skills to create sustainable livelihoods and become successful entrepreneurs.',
      raised: '40,000',
      goal: '80,000',
      progress: 50,
    },
    {
      id: 'goat',
      image: cause6,
      title: 'Goat Rearing: Empowering Women',
      description: 'Providing women with the tools and knowledge to succeed in goat farming, boosting independence.',
      raised: '80,000',
      goal: '40,000',
      progress: 50,
    },
  ];

  return (
    <section className="causes-section-four" style={{ backgroundColor: '#f8f8f8' }}>
      <div className="auto-container">
        <div className="sec-title text-center">
        <h1 style={{ marginTop: '-5px' }}>Our Latest Projects</h1>


          <p className="text">
            Transforming Lives through Strategic Initiatives in Education, Health, and Community Empowerment
          </p>
        </div>
        <div className="cause-wrapper">
          <Grid container spacing={3}>
            {projects.map((project, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <div className="cause-block-four">
                  <div className="inner-box">
                    <div className="image">
                      <img src={project.image} alt={project.title} />
                    </div>
                    <div className="lower-content">
                      <div className="wrapper-box">
                        <h4>
                          <Link
                            to={`/${project.id}/${project.id}`}
                            className="project-link"
                            style={{
                              color: 'inherit',
                              '&:hover': {
                                color: 'red',
                              },
                            }}
                          >
                            {project.title}
                          </Link>
                        </h4>
                        <div className="text">{project.description}</div>
                        <div className="info-box">
                          <div className="raised-donation">
                            <span className="raise-donation">Raised:</span> {project.raised}
                          </div>
                          <div className="count-box">
                            <span className="count-text" data-speed="3000" data-stop={project.progress}>
                              {project.progress}
                            </span>
                            <span className="affix">%</span>
                          </div>
                          <div className="goaled-donation">
                            <span className="goal-donation">Goal:</span> {project.goal}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </section>
  );
};

export default DonationProjects;